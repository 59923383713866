import {useContext, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { AuthContext } from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";

export default function Login() {
  const LOGIN_URL = "/login/";

  const schema = Yup.object().shape({
    email: Yup.string()
        .required("Email is a required field")
        .email("Invalid email format"),
    password: Yup.string()
        .required('Password is a required field'),
  })

  const {user, setUser} = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.email) {
      navigate('/');
    }
  });

  return (
    <>
      {!user.email ? (
      <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t("login-page.title")}</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                email:'',
                password:'',
                }}
                onSubmit={async (values) => {
                  axios.post(LOGIN_URL, values)
                      .then((res) => {
                        if (res.status === 200) {
                          navigate("/");
                          setUser(res.data.user)
                        }
                      })
                      .catch((error) => {
                        let error_msg = "";
                        Object.keys(error.response.data).forEach(function (e) {
                          error_msg += error.response.data[e][0];
                        });
                        toast.error(error_msg);
                      });
                }}
                  validationSchema={schema}
                >
                {({errors, touched }) => (
                <Form className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      {t("login-page.email")}
                    </label>
                    <div className="mt-1">
                      <Field
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id='email'
                        type='email'
                        name='email'
                      />
                      {errors.email && touched.email ? (
                          <div className='text-red-700'>{errors.email}</div>
                      ): null }
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      {t("login-page.password")}
                    </label>
                    <div className="mt-1">
                      <Field
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id='password'
                          type='password'
                          name='password'
                      />
                      {errors.password && touched.password ? (
                          <div className='text-red-700'>{errors.password}</div>
                      ): null }
                    </div>
                    <a href="/password-reset" className="text-gray-700 hover:text-blue-700 margin-top:0">
                      <p className="text-right">{t("login-page.forgot")}</p>
                    </a>
                  </div>
                  <div>
                    <label htmlFor="captcha" className="block text-sm font-medium text-gray-700">
                      Captcha
                    </label>
                    <div className="bg-gray-100 rounded-lg px-4 py-16 sm:p-16">
                      {/* Some diagram */}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {t("login-page.login")}
                    </button>
                  </div>
                </Form>
                )}
            </Formik>
          </div>
        </div>
      </div>
          ):(
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">You are already logged in!</h1>
          </div>
      )}
    </>
  )
}
