import Stepper from './Stepper'
import Step from './Step'
import React, {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {useTranslation} from "react-i18next";
import Banner from "../Banner";
import {FormContext} from "../../contexts/FormContext";
import axios from "../../api/axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ResultContext from "../../contexts/ResultContext";

function Survey() {
  const {user} = useContext(AuthContext);
  const data = useContext(FormContext)
  const {activeStepIndex} = useContext(FormContext)
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {setResult} = useContext(ResultContext);
  function getValueOrNull(fieldName, formData) {
    return formData[fieldName] !== "" ? formData[fieldName] : null;
  }
  let se_r, se_l
  data.formData.se_rUnit === '+' ? se_r = data.formData.se_r : se_r = data.formData.se_r * -1
  data.formData.se_lUnit === '+' ? se_l = data.formData.se_r : se_l = data.formData.se_l * -1
  const values = {
    year_of_birth: data.formData.Year,
    month_of_birth: data.formData.Month,
    gender: data.formData.sex,
    height: data.formData.Height_xUnit === 'cm' ? data.formData.Height_x : data.formData.Height_x * 2.54,
    weight: data.formData.WeightUnit === 'kg' ? data.formData.Weight : data.formData.Weight * 0.45359237,
    region: data.formData.Region,
    mother_myopia: data.formData.mother_myopia ==='not' ? 'No' : data.formData.mother_myopia,
    father_myopia: data.formData.father_myopia ==='not' ? 'No' : data.formData.father_myopia,
    time_spent_read_and_write: data.formData.p1_readingwritingH,
    tv_distance: data.formData.TV_distanceUnit === 'm' ? data.formData.TV_distance : data.formData.TV_distance * 0.304,
    total_hour_in_close_vision_tasks: data.formData.p1_totalnearH,
    outdoor: data.formData.p1_outdoorH,
    sphere_right_eye: data.formData.sphere_rUnit === '+' ? data.formData.sphere_r : data.formData.sphere_r * -1,
    sphere_left_eye: data.formData.sphere_lUnit === '+' ? data.formData.sphere_l : data.formData.sphere_l * -1,
    cylinder_right_eye: data.formData.cylinder_rUnit === '+' ? data.formData.cylinder_r : data.formData.cylinder_r * -1,
    cylinder_left_eye: data.formData.cylinder_lUnit === '+' ? data.formData.cylinder_l : data.formData.cylinder_l * -1,
    axis_right_eye: getValueOrNull('axis_r', data.formData),
    axis_left_eye: getValueOrNull('axis_l', data.formData),
    se1_right_eye: data.formData.se_r ? se_r : null,
    se1_left_eye: data.formData.se_l ? se_l : null,
    ucva_right_eye: data.formData.ucva_l,
    ucva_left_eye: data.formData.ucva_r,
    bcva_right_eye: data.formData.bcva_l,
    bcva_left_eye: data.formData.bcva_r,
    k1d_right_eye: getValueOrNull('K1D_r', data.formData),
    k1d_left_eye: getValueOrNull('K1D_l', data.formData),
    k11_left_eye: getValueOrNull('K11_l', data.formData),
    k11_right_eye: getValueOrNull('K11_r', data.formData),
    k2d_left_eye: getValueOrNull('K2D_l', data.formData),
    k2d_right_eye: getValueOrNull('K2D_r', data.formData),
    k22_left_eye: getValueOrNull('K22_l', data.formData),
    k22_right_eye: getValueOrNull('K22_r', data.formData),
    al_left_eye: getValueOrNull('AL1_l', data.formData),
    al_right_eye: getValueOrNull('AL1_r', data.formData),
    acd_left_eye: getValueOrNull('ACD_l', data.formData),
    acd_right_eye: getValueOrNull('ACD_r', data.formData),
    npc_left_eye: getValueOrNull('NearPointCM_l', data.formData),
    npc_right_eye: getValueOrNull('NearPointCM_r', data.formData),
    ampcm_left_eye: getValueOrNull('AmpCM_l', data.formData),
    ampcm_right_eye: getValueOrNull('AmpCM_r', data.formData),
    ast1_left_eye: getValueOrNull('AST1_l', data.formData),
    ast1_right_eye: getValueOrNull('AST1_r', data.formData),
    ampd_left_eye: getValueOrNull('AmpD_l', data.formData),
    ampd_right_eye: getValueOrNull('AmpD_r', data.formData),
    ad1_left_eye: getValueOrNull('AD1_l', data.formData),
    ad1_right_eye: getValueOrNull('AD1_r', data.formData),
    type_machine_learning: 6,
    user: user.pk,
  }
  const SaveData = () => {
    if (activeStepIndex > 2) {
      axios.post('/calculator/', values)
        .then((res) => {
          if (res.status === 201) {
            setResult(res.data)
            navigate("/results")
          }
        })
        .catch(() => {
          toast.error('Server error');
        });
    } else {
      toast.error('Complete at least the first 3 sections.')
    }
  }
  return (
    <div className="bg-white">
      {user.is_debug_account ?
        <div className="site-id">
          S03
        </div>
        : null}
      <Banner/>
      <div className="mx-auto">
        <div className="flex flex-col items-center justify-start">
          <div className="text-center">
            <p
              className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
              {t("S03.S1")}
              {user.is_debug_account && (
                <span className='tooltip-text'>
                              S03:S1
                            </span>
              )}
            </p>
            <p
              className={`mt-4 max-w-2xl text-xl text-gray-500 mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
              {t("S03.S11")}
              {user.is_debug_account && (
                <span className='tooltip-text'>
                              S03:S11
                            </span>
              )}
            </p>
          </div>
          <Stepper/>
          <Step/>
          <div className="text-center mt-4 mb-2 mr-2">
            <button disabled={!(activeStepIndex > 2)} onClick={() => SaveData()} className={`${!(activeStepIndex > 2) ? 'survey-result-btn-disabled': 'survey-result-btn border border-green-500'}`}>
              {t("S03.S213")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Survey;