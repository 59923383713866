import confusionMatrix from '../../confusionMatrix.png'
import roc from '../../ROC2.png'
import ResultContext from "../../contexts/ResultContext";
import {useContext} from "react";

export default function ExaminationResult() {
  const { result } = useContext(ResultContext)
    return (
      <>
        <div className="min-h-full flex flex-col justify-center sm:px-10 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Examination result</h2>
          </div>
  
          <div className=" px-8 m:px-12 lg:px-16 ">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="flex flex-col">
                <table className='examination-result-table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Neural network</th>
                      <th>Fuzzy Logic</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Confusion matrix</td>
                      <td >
                        <img className='scale-50 p-0 m-auto' src={confusionMatrix} alt="confusionMatrix"></img>
                      </td>
                      <td >
                        <img className='scale-50 p-0 m-auto' src={confusionMatrix} alt="confusionMatrix"></img>
                      </td>
                    </tr>
                    <tr>
                      <td>ROC</td>
                      <td colSpan={2} className='p-0' >
                      <img className='scale-50 m-auto' src={roc} alt="roc"></img>
                      </td>
                    </tr>
                    <tr>
                      <td>Accuracy</td>
                      <td>{result.acc_org}</td>
                      <td>0.724</td>
                    </tr>
                    <tr>
                      <td>AUC</td>
                      <td>{result.auc_org}</td>
                      <td>0.729</td>
                    </tr>
                    <tr>
                      <td>G-Mean</td>
                      <td>{result.g_mean_org}</td>
                      <td>0.711</td>
                    </tr>
                    <tr>
                      <td>F1</td>
                      <td>{result.f1_org}</td>
                      <td>0.693</td>
                    </tr>
                    <tr>
                      <td>Sensitivity</td>
                      <td>{result.sens_org}</td>
                      <td>0.693</td>
                    </tr>
                    <tr>
                      <td>Specificity</td>
                      <td>{result.spec_org}</td>
                      <td>0.469</td>
                    </tr>
                    <tr>
                      <td>Precision</td>
                      <td>{result.prec_org}</td>
                      <td>0.391</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
