import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../../index.css';
import UserVisits from "../../components/Account/UserVisits";

const UserVisit = () => {
  return (
    <>
      <Header />
      <UserVisits />
      <Footer />
    </>
  );
}

export default UserVisit;
