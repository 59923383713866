import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useContext} from "react";
import {FormContext} from "../../../contexts/FormContext";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import * as Yup from "yup";

function EyeKeratometry() {
  const {t} = useTranslation();
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    K1D_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(10,'Minimal value is 10')
      .max(55, "Maximal value is 55"),
    K1D_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(10,'Minimal value is 10')
      .max(55, "Maximal value is 55"),
    K11_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(0,'Minimal value is 0')
      .max(55, "Maximal value is 55"),
    K11_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(0,'Minimal value is 0')
      .max(55, "Maximal value is 55"),
    K2D_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(-250,'Minimal value is -250')
      .max(55, "Maximal value is 55"),
    K2D_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(-250,'Minimal value is -250')
      .max(55, "Maximal value is 55"),
    K22_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(18,'Minimal value is 18')
      .max(30, "Maximal value is 30"),
    K22_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(18,'Minimal value is 18')
      .max(30, "Maximal value is 30"),
  });

  const initialValues = {
    K1D_l: formData.K1D_l ?? '',
    K1D_r: formData.K1D_r ?? '',
    K11_l: formData.K11_l ?? '',
    K11_r: formData.K11_r ?? '',
    K2D_l: formData.K2D_l ?? '',
    K2D_r: formData.K2D_r ?? '',
    K22_l: formData.K22_l ?? '',
    K22_r: formData.K22_r ?? '',
  }
  const previousStep = () => {
    setActiveStepIndex(activeStepIndex-1);
  }
  return (
    <div className="mx-10">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = {...formData, ...values};
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({setValues}) => (
          <Form autoComplete='off'>
            <div className="text-center">
              <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
                {t("S03.S021")}
                <div className="text-sm text-gray-500">Optional</div>
              </p>
            </div>
            <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
              <div className="w-full">
                <button type='button' onClick={() => {
                  setValues(initialValues)
                }} className="float-right">
                  <TrashIcon className="survey-trash-icon"/>
                </button>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'></label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Right eye
                  </div>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Left eye
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  1. {t("S03.S131d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K1D_r'
                    name='K1D_r'
                    type='number'
                  />
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K1D_l'
                    name='K1D_l'
                    type='number'
                  />
                  <div className="flex-1">
                    <div className="text-red-700 ml-2">
                      <ErrorMessage name='K1D_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='K1D_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 text-center'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  2. {t("S03.S132d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K11_r'
                    name='K11_r'
                    type='number'
                  />
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K11_l'
                    name='K11_l'
                    type='number'
                  />
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='K11_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='K11_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 text-center'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  3. {t("S03.S133d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K2D_r'
                    name='K2D_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K2D_l'
                    name='K2D_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='K2D_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='K2D_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 text-center'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  4. {t("S03.S134d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K22_r'
                    name='K22_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='K22_l'
                    name='K22_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='K22_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='K22_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-right mt-2 mb-5 mr-2'>
              <button className="survey-back-btn" onClick={previousStep}>
                {t("S03.S211")}
              </button>
              <button className="survey-next-btn">
                {t("S03.S212")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EyeKeratometry;