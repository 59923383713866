import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import '../../index.css';
import Survey from "../../components/Survey/Survey";

const Surveys = () => {
  return (
    <>
      <Header />
      <Survey />
      <Footer />
    </>
  );
}

export default Surveys;