import React from "react";
import '../bigger-font.css';

const FontSizeSelector = () => {
    return (
        <div className="flex items-baseline mr-8">
          <button 
            className='text-md mr-1 animation-font-button' 
            onClick={() => {
            if (document.querySelector('html').classList.contains('big-font'))
              document.querySelector('html').classList.remove('big-font');
              sessionStorage.setItem('font-size', 'normal');
            }}
          >
            A
          </button>

          <button
            className='text-xl animation-font-button'
            onClick={() => {
            if (!document.querySelector('html').classList.contains('big-font'))
              document.querySelector('html').classList.add('big-font');
              sessionStorage.setItem('font-size', 'big');
            }}
          >
            A
          </button>
        </div>
    );
}

export default FontSizeSelector;
