import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Register from '../components/Register';
import '../index.css';

const SignUp = () => {
    return (
        <>
            <Header />
            <Register />
            <Footer />
        </>
    );
}

export default SignUp;
