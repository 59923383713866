import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ResetPwdConfirm from "../components/ResetPasswordConfirm";
import '../index.css';

const ResetPasswordConfirm = () => {
    return (
        <>
            <Header />
            <ResetPwdConfirm />
            <Footer />
        </>
    );
}

export default ResetPasswordConfirm;