import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useContext} from "react";
import {FormContext} from "../../../contexts/FormContext";
import * as yup from "yup";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

function EyeRefraction() {
  const {t} = useTranslation();
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    sphere_l: Yup.number()
      .required("This field is required")
      .min(0,"It must be a positive number"),
    sphere_lUnit: Yup.string()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    sphere_r: Yup.number()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    sphere_rUnit: Yup.string()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    cylinder_l: Yup.number()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    cylinder_lUnit: Yup.string()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    cylinder_r: Yup.number()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    cylinder_rUnit: Yup.string()
      .min(0,"It must be a positive number")
      .required("This field is required"),
    axis_l: Yup.number()
      .min(0,"It must be a positive number"),
    axis_r: Yup.number()
      .min(0,"It must be a positive number"),
    // se_l: Yup.number()
    //   .min(0,"It must be a positive number")
    //   .test(
    //     'se_l',
    //     'Check se_l value',
    //     function (se_l) {
    //       const {cylinder_l, sphere_l} = this.parent;
    //       return se_l === null || se_l === undefined || se_l === (cylinder_l / 2) + sphere_l
    //     },
    //   )
    //   .nullable(),
    // se_lUnit: Yup.string().required("This field is required"),
    // se_r: Yup.number()
    //   .min(0,"It must be a positive number")
    //   .test(
    //     'se_r',
    //     'Check se_r value',
    //     function (se_r) {
    //       const {cylinder_r, sphere_r} = this.parent;
    //       return se_r === null || se_r === undefined || se_r === (cylinder_r / 2) + sphere_r
    //     },
    //   )
    //   .nullable(),
    // se_rUnit: Yup.string().required("This field is required"),
  });
  const initialValues = {
    sphere_l: formData.sphere_l ?? '',
    sphere_lUnit: formData.sphere_lUnit ?? '-',
    sphere_r: formData.sphere_r ?? '',
    sphere_rUnit: formData.sphere_rUnit ?? '-',
    cylinder_l: formData.cylinder_l ?? '',
    cylinder_lUnit: formData.cylinder_lUnit ?? '-',
    cylinder_r: formData.cylinder_r ?? '',
    cylinder_rUnit: formData.cylinder_rUnit ?? '-',
    axis_l: formData.axis_l ?? '',
    axis_r: formData.axis_r ?? '',
    // se_l: '',
    // se_lUnit: '-',
    // se_r: '',
    // se_rUnit: '-'
  }
  const previousStep = () => {
    setActiveStepIndex(activeStepIndex-1);
  }
  return (
    <div className="mx-10">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
      {({ setValues }) => (
          <Form autoComplete='off'>
          <div className="text-center">
            <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
              {t("S03.S019")}
            </p>
          </div>
          <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
            <div className="w-full">
              <button type='button' onClick={() => {
                setValues(initialValues)
              }} className="float-right">
                <TrashIcon className="survey-trash-icon"/>
              </button>
            </div>
            <div className='flex flex-wrap mx-2 w-full'>
              <label className='my-2 font-bold w-1/3 inline-block'></label>
              <div className='flex flex-wrap w-2/3 inline-block'>
                <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                  Right eye
                </div>
                <div className='mx-8 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                  Left eye
                </div>
              </div>
            </div>
            <div className='flex flex-wrap mx-2 w-full mt-2'>
              <label className='my-2 text-left font-bold w-1/3 inline-block'>
                1. {t("S03.S125")}
              </label>
              <div className='flex flex-wrap w-3/5 inline-block'>
                <Field
                  className='bg-gray-200 rounded-lg p-2 inline-block'
                  id='sphere_rUnit'
                  name='sphere_rUnit'
                  as='select'
                >
                  <option value="+">+</option>
                  <option value="-">-</option>
                </Field>
                <Field
                  className='mr-5 bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='sphere_r'
                  name='sphere_r'
                  type='number'
                />
                <Field
                  className='bg-gray-200 rounded-lg p-2 inline-block'
                  id='sphere_lUnit'
                  name='sphere_lUnit'
                  as='select'
                >
                  <option value="+">+</option>
                  <option value="-">-</option>
                </Field>
                <Field
                  className='bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='sphere_l'
                  name='sphere_l'
                  type='number'
                />
                <div className="flex-1">
                  <div className="text-red-700">
                    <ErrorMessage name='sphere_r' component="div"/>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-red-700 text-right mr-8">
                    <ErrorMessage name='sphere_l' component="div"/>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap mx-2 w-full mt-2'>
              <label className='my-2 text-left font-bold w-1/3 inline-block'>
                2. {t("S03.S126")}
              </label>
              <div className='flex flex-wrap w-3/5 inline-block'>
                <Field
                  className='bg-gray-200 rounded-lg p-2 inline-block'
                  id='cylinder_rUnit'
                  name='cylinder_rUnit'
                  as='select'
                >
                  <option value="+">+</option>
                  <option value="-">-</option>
                </Field>
                <Field
                  className='mr-5 bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='cylinder_r'
                  name='cylinder_r'
                  type='number'
                />
                <Field
                  className='bg-gray-200 rounded-lg p-2 inline-block'
                  id='cylinder_lUnit'
                  name='cylinder_lUnit'
                  as='select'
                >
                  <option value="+">+</option>
                  <option value="-">-</option>
                </Field>
                <Field
                  className='bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='cylinder_l'
                  name='cylinder_l'
                  type='number'
                />
                <div className="flex-1">
                  <div className="text-red-700">
                    <ErrorMessage name='cylinder_r' component="div"/>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-red-700 text-right mr-8">
                    <ErrorMessage name='cylinder_l' component="div"/>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap mx-2 w-full mt-2'>
              <label className='my-2 text-left font-bold w-1/3 inline-block'>
                3. {t("S03.S127")}
              </label>
              <div className='flex flex-wrap w-3/5 inline-block'>
                <div className='bg-white rounded-lg p-2 mr-8 inline-block'/>
                <Field
                  className='mr-5 bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='axis_r'
                  name='axis_r'
                  type='number'
                />
                <div className='bg-white rounded-lg p-2 mr-8 inline-block'/>
                <Field
                  className='bg-gray-200 rounded-lg p-2 w-1/3 inline-block'
                  id='axis_l'
                  name='axis_l'
                  type='number'
                />
                <div className="flex-1">
                  <div className="text-red-700">
                    <ErrorMessage name='axis_r' component="div"/>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-red-700 text-right mr-8">
                    <ErrorMessage name='axis_l' component="div"/>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className='flex flex-wrap mx-2 w-full mt-2'>*/}
            {/*  <label className='my-2 text-left font-bold w-1/3 inline-block'>*/}
            {/*    4. {t("S03.S128")}*/}
            {/*  </label>*/}
            {/*  <div className='flex flex-wrap w-3/5 inline-block'>*/}
            {/*    <Field*/}
            {/*      className='bg-gray-200 rounded-lg p-2 inline-block'*/}
            {/*      id='se_rUnit'*/}
            {/*      name='se_rUnit'*/}
            {/*      as='select'*/}
            {/*    >*/}
            {/*      <option value="+">+</option>*/}
            {/*      <option value="-">-</option>*/}
            {/*    </Field>*/}
            {/*    <Field*/}
            {/*      className='mr-5 bg-gray-200 rounded-lg p-2 w-1/3 inline-block'*/}
            {/*      id='se_r'*/}
            {/*      name='se_r'*/}
            {/*      type='number'*/}
            {/*    />*/}
            {/*    <Field*/}
            {/*      className='bg-gray-200 rounded-lg p-2 inline-block'*/}
            {/*      id='se_lUnit'*/}
            {/*      name='se_lUnit'*/}
            {/*      as='select'*/}
            {/*    >*/}
            {/*      <option value="+">+</option>*/}
            {/*      <option value="-">-</option>*/}
            {/*    </Field>*/}
            {/*    <Field*/}
            {/*      className='bg-gray-200 rounded-lg p-2 w-1/3 inline-block'*/}
            {/*      id='se_l'*/}
            {/*      name='se_l'*/}
            {/*      type='number'*/}
            {/*    />*/}
            {/*    <div className="flex-1">*/}
            {/*      <div className="text-red-700">*/}
            {/*        <ErrorMessage name='se_r' component="div"/>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="flex-1">*/}
            {/*      <div className="text-red-700 text-right mr-8">*/}
            {/*        <ErrorMessage name='se_l' component="div"/>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
            <div className='text-right mt-2 mb-5 mr-2'>
              <button className="survey-back-btn" onClick={previousStep}>
                {t("S03.S211")}
              </button>
              <button className="survey-next-btn">
                {t("S03.S212")}
              </button>
            </div>
        </Form>
      )}
      </Formik>
    </div>
  );
  
}

export default EyeRefraction;