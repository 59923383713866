import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ExaminationResult from '../../components/ExpertSurvey/ExaminationResult';
import '../../index.css';

const ExaminationForm = () => {
    return (
        <>
            <Header />
            <ExaminationResult />
            <Footer />
        </>
    );
}

export default ExaminationForm;
