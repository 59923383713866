import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from "./locales/translationEN.json";
import translationPL from "./locales/translationPL.json";

const resources = {
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
      resources,
      fallbackLng: "en",
      debug: true,
      interpolation: {
          escapeValue: false
      }
  });

export default i18n;