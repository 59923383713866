import React from 'react';
import Header from '../components/Header';
import Center from '../components/Center';
import Footer from '../components/Footer';
import '../index.css';

const Home = () => {
    return (
        <>
            <Header />
            <Center />
            <Footer />
        </>
    );
}

export default Home;
