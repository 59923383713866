import React, {useContext } from "react";
import '../../index.css';
import Banner from "../Banner";
import axios from "../../api/axios";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import ResultContext from "../../contexts/ResultContext";
import * as Yup from "yup";
import {AuthContext} from "../../contexts/AuthContext";

const AdvancedSurvey = () => {
    const navigate = useNavigate();
    const { setResult, setAdv, expert } = useContext(ResultContext)
    const { user } = useContext(AuthContext);
    const ADVANCED_URL = expert ? '/calculator-data-science/' : '/calculator-advance/'

    const schema = Yup.object().shape({
        AgeY: Yup.number()
            .required("This field is required")
            .min(1, 'You must be at least 1 year old'),
        type: Yup.string()
            .required('This field is required'),
        SE1: Yup.number()
            .required('This field is required')
            .min(-10,"SE can't be below -10")
            .max(6,"SE can't be above 6"),
        AL1: Yup.number()
            .required('This field is required')
            .min(18,"AL can't be below 18")
            .max(30,"AL can't be above 30"),
        Height_x: Yup.number()
            .required("This field is required")
            .min(90, 'Minimal height is 90cm')
            .max(180, 'Maximal height is 180cm'),
        Weight: Yup.number()
            .required("This field is required"),
        Region: Yup.string()
            .required('This field is required'),
        ParentaL_myopia: Yup.string()
            .required('This field is required'),
        TVdistance_m: Yup.number()
            .required('This field is required')
            .min(1,"TVdistance_m can't be below 1")
            .max(5,"TVdistance_m can't be above 5"),
        p1_readingwritingH: Yup.number()
            .required('This field is required')
            .min(0,"p1_readingwritingH can't be below 0")
            .max(10, "p1_readingwritingH can't be above 10"),
        p1_totalnearH: Yup.number()
            .required('This field is required')
            .min(1,"p1_totalnearH can't be below 1")
            .max(18, "p1_totalnearH can't be above 18"),
        NearPointCM: Yup.number()
            .required('This field is required')
            .min(1,"NearPointCM can't be below 1")
            .max(3, "NearPointCM can't be above 3"),
        AmpCM: Yup.number()
            .required('This field is required')
            .min(3,"AmpCM can't be below 3")
            .max(15, "AmpCM can't be above 15"),
        AST1: Yup.number()
            .required('This field is required')
            .min(-5,"AST1 can't be below 5")
            .max(5, "AST1 can't be above 5"),
        K11: Yup.number()
            .required('This field is required')
            .min(0,"K11 can't be below 0")
            .max(55, "K11 can't be above 55"),
        K1D: Yup.number()
            .required('This field is required')
            .min(10,"K1D can't be below 10")
            .max(55, "K1D can't be above 55"),
        K2D: Yup.number()
            .required('This field is required')
            .min(-250,"K2D can't be below -250")
            .max(50, "K2D can't be above 50"),
        K21: Yup.number()
            .required('This field is required')
            .min(0,"K21 can't be below 0")
            .max(50, "K21 can't be above 50"),
        BCV: Yup.number()
            .required('This field is required')
            .min(10,"BCV can't be below 10")
            .max(50, "BCV can't be above 50"),
        AmpD: Yup.number()
            .required('This field is required')
            .min(5,"AmpD can't be below 5")
            .max(35, "AmpD can't be above 35"),
        AD1: Yup.number()
            .required('This field is required')
            .min(2,"AD1 can't be below 2")
            .max(5, "AD1 can't be above 5"),
    })

    return (
        <>
            {user.is_debug_account ?
                <div className="site-id">
                    S04
                </div>
                : null}
            <Banner />
            <div>
                <div className="text-center">
                    <p className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
                        Medical myopia survey
                        {user.is_debug_account && (
                            <span className='tooltip-text'>
                              S04:S2
                            </span>
                        )}
                    </p>
                    <p className={`mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
                        Provide medical data for precise myopia risk calculation
                        {user.is_debug_account && (
                            <span className='tooltip-text'>
                              S04:S21
                            </span>
                        )}
                    </p>
                </div>
                <div className="border rounded-lg mt-16 mx-10 p-2 text-center shadow">
                    <Formik
                        initialValues={{
                            type:'1',
                            AgeY:'',
                            SE1:'',
                            AL1:'',
                            Height_x:'',
                            Weight:'',
                            Region:'1',
                            ParentaL_myopia:'1',
                            TVdistance_m:'',
                            p1_readingwritingH:'',
                            p1_totalnearH: '',
                            NearPointCM: '',
                            AmpCM: '',
                            AST1: '',
                            K11: '',
                            K1D: '',
                            K2D: '',
                            K21: '',
                            BCV: '',
                            AmpD: '',
                            AD1: '',
                        }}
                        onSubmit={async(values) => {
                            const data = {
                                type_machine_learning: values.type,
                                AgeY: values.AgeY,
                                SE1: values.SE1,
                                AL1: values.AL1,
                                Height_x: values.Height_x,
                                Weight: values.Weight,
                                Region: values.Region,
                                ParentaL_myopia: values.ParentaL_myopia,
                                TVdistance_m: values.TVdistance_m,
                                p1_readingwritingH: values.p1_readingwritingH,
                                p1_totalnearH: values.p1_totalnearH,
                                NearPointCM: values.NearPointCM,
                                AmpCM: values.AmpCM,
                                AST1: values.AST1,
                                K11: values.K11,
                                K1D: values.K1D,
                                K2D: values.K2D,
                                K21: values.K21,
                                BCV: values.BCV,
                                AmpD: values.AmpD,
                                AD1: values.AD1,
                            }
                            axios.post(ADVANCED_URL, data)
                                .then((res) => {
                                    if (res.status === 201) {
                                        if(expert) {
                                            setResult(res.data)
                                            navigate('/examination-result')
                                        } else {
                                            setResult(res.data)
                                            setAdv(true)
                                            navigate("/results")
                                        }
                                    }
                                })
                                .catch(() => {
                                    toast.error('Server error');
                                });
                        }}
                        validationSchema={schema}
                    >
                        {({errors, touched }) => (
                            <Form>
                                <div className="text-left mt-10">
                                    <ol className="list-decimal list-inside mx-4 md:mx-10">
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Choose machine learning method
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S211
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='type'
                                                name='type'
                                                as='select'
                                            >
                                                <option value='1'>Regression</option>
                                                <option value='1'>Logistic Regression</option>
                                                <option value='1'>kNN</option>
                                                <option value='1'>SVM</option>
                                                <option value='1'>Decision Tree</option>
                                                <option value='1'>Random Forests</option>
                                                <option value='1'>Boosting</option>
                                                <option value='1'>Neutral Networks</option>
                                            </Field>
                                            {errors.type && touched.type ? (
                                                <div className='text-red-700'>{errors.type}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Patient's age
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S212
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AgeY'
                                                name='AgeY'
                                                type='number'
                                                min='1'
                                            />
                                            {errors.AgeY && touched.AgeY ? (
                                                <div className='text-red-700'>{errors.AgeY}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Spherical equivalent
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S213
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='SE1'
                                                name='SE1'
                                                type='number'
                                            />
                                            {errors.SE1 && touched.SE1 ? (
                                                <div className='text-red-700'>{errors.SE1}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Axial length
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S214
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AL1'
                                                name='AL1'
                                                type='number'
                                                min='1'
                                                placeholder='value in centimeters'
                                            />
                                            {errors.AL1 && touched.AL1 ? (
                                                <div className='text-red-700'>{errors.AL1}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Height
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S215
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='Height_x'
                                                name='Height_x'
                                                type='number'
                                                placeholder='value in dioptres'
                                            />
                                            {errors.Height_x && touched.Height_x ? (
                                                <div className='text-red-700'>{errors.Height_x}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Weight
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S216
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='Weight'
                                                name='Weight'
                                                type='number'
                                            />
                                            {errors.Weight && touched.Weight ? (
                                                <div className='text-red-700'>{errors.Weight}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                                                Region
                                                {user.is_debug_account && (
                                                    <span className='tooltip-text'>
                                                      S04:S217
                                                    </span>
                                                )}
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='region'
                                                name='region'
                                                type='number'
                                                as='select'
                                            >
                                                <option value='1'>Urban</option>
                                                <option value='2'>Rural</option>
                                            </Field>
                                            {errors.region && touched.region ? (
                                                <div className='text-red-700'>{errors.region}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>Parental myopia
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Did the parents have myopia?"
                                                    ):(
                                                        "S04:S218 and S04:S218a[Did the parents have myopia?]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='ParentaL_myopia'
                                                name='ParentaL_myopia'
                                                type='number'
                                                as='select'
                                            >
                                                <option value='1'>One of them</option>
                                                <option value='2'>Both</option>
                                                <option value='0'>None</option>
                                            </Field>
                                            {errors.ParentaL_myopia && touched.ParentaL_myopia ? (
                                                <div className='text-red-700'>{errors.ParentaL_myopia}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>TV distance
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Distance from the TV in meters"
                                                    ):(
                                                        "S04:S219 and S04:S219a[Distance from the TV in meters]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='TVdistance_m'
                                                name='TVdistance_m'
                                                type='number'
                                            />
                                            {errors.TVdistance_m && touched.TVdistance_m ? (
                                                <div className='text-red-700'>{errors.TVdistance_m}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>Reading/Writing time
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Time spent reading and writing. " +
                                                        "Regardless of the type of reading. (e.g. book, article on " +
                                                        "computer or phone)"
                                                    ):(
                                                        "S04:S219 and S04:S219a[Time spent reading " +
                                                        "and writing. Regardless of the type of reading. (e.g. " +
                                                        "book, article on computer or phone)]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='p1_readingwritingH'
                                                name='p1_readingwritingH'
                                                type='number'
                                            />
                                            {errors.p1_readingwritingH && touched.p1_readingwritingH ? (
                                                <div className='text-red-700'>{errors.p1_readingwritingH}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>Near work time
                                                <span className='tooltip-text'>
                                                {!user.is_debug_account ? ("Daily time of all activities done at close range"
                                                ):(
                                                "S04:S220 and S04:S220a[Daily time of all activities done at close range]")}

                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='p1_totalnearH'
                                                name='p1_totalnearH'
                                                type='number'
                                            />
                                            {errors.p1_totalnearH && touched.p1_totalnearH ? (
                                                <div className='text-red-700'>{errors.p1_totalnearH}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>NearPointCM
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Near Point of Convergence in centimeter"
                                                    ):(
                                                        "S04:S221 and S04:S221a[Near Point of Convergence in centimeter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='NearPointCM'
                                                name='NearPointCM'
                                                type='number'
                                            />
                                            {errors.NearPointCM && touched.NearPointCM ? (
                                                <div className='text-red-700'>{errors.NearPointCM}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>AmpCM
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Amplitude of accommodation centimeter"
                                                    ):(
                                                        "S04:S222 and S04:S222a[Amplitude of accommodation centimeter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AmpCM'
                                                name='AmpCM'
                                                type='number'
                                            />
                                            {errors.AmpCM && touched.AmpCM ? (
                                                <div className='text-red-700'>{errors.AmpCM}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>AST1
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Astigmatism Cylinder in diopter"
                                                    ):(
                                                        "S04:S223 and S04:S223a[Astigmatism Cylinder in diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AST1'
                                                name='AST1'
                                                type='number'
                                            />
                                            {errors.AST1 && touched.AST1 ? (
                                                <div className='text-red-700'>{errors.AST1}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>K11
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Flat meridian of Cornea in Diopter"
                                                    ):(
                                                        "S04:S224 and S04:S224a[Flat meridian of Cornea in Diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='K11'
                                                name='K11'
                                                type='number'
                                            />
                                            {errors.K11 && touched.K11 ? (
                                                <div className='text-red-700'>{errors.K11}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>K1D
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Flat Keratometry in diopter"
                                                    ):(
                                                        "S04:S225 and S04:S225a[Flat Keratometry in diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='K1D'
                                                name='K1D'
                                                type='number'
                                            />
                                            {errors.K1D && touched.K1D ? (
                                                <div className='text-red-700'>{errors.K1D}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>K2D
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Steep Keratometry in diopter"
                                                    ):(
                                                        "S04:S226 and S04:S226a[Steep Keratometry in diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='K2D'
                                                name='K2D'
                                                type='number'
                                            />
                                            {errors.K2D && touched.K2D ? (
                                                <div className='text-red-700'>{errors.K2D}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>K21
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Steep meridian of Cornea in diopter"
                                                    ):(
                                                        "S04:S227 and S04:S227a[Steep meridian of Cornea in diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='K21'
                                                name='K21'
                                                type='number'
                                            />
                                            {errors.K21 && touched.K21 ? (
                                                <div className='text-red-700'>{errors.K21}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>BCV
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Best Corrected Visual Acuity as fraction of 20"
                                                    ):(
                                                        "S04:S228 and S04:S228a[Best Corrected Visual Acuity as fraction of 20]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='BCV'
                                                name='BCV'
                                                type='number'
                                            />
                                            {errors.BCV && touched.BCV ? (
                                                <div className='text-red-700'>{errors.BCV}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>AmpD
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Amplitude of accommodation in diopter"
                                                    ):(
                                                        "S04:S229 and S04:S229a[Amplitude of accommodation in diopter]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AmpD'
                                                name='AmpD'
                                                type='number'
                                            />
                                            {errors.AmpD && touched.AmpD ? (
                                                <div className='text-red-700'>{errors.AmpD}</div>
                                            ): null }
                                        </li>
                                        <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                                            <div className='tooltip'>AD1
                                                <span className='tooltip-text'>
                                                    {!user.is_debug_account ? ("Aqueous depth in Left Eye in mm"
                                                    ):(
                                                        "S04:S230 and S04:S230a[Aqueous depth in Left Eye in mm]")}
                                                </span>
                                            </div>
                                            <Field
                                                className='survey-input-control'
                                                id='AD1'
                                                name='AD1'
                                                type='number'
                                            />
                                            {errors.AD1 && touched.AD1 ? (
                                                <div className='text-red-700'>{errors.AD1}</div>
                                            ): null }
                                        </li>
                                    </ol>
                                </div>
                                <div className='text-right mt-4 mb-2 mr-2'>
                                    <button className="survey-result-btn">
                                        Finish
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default AdvancedSurvey;
