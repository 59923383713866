import { PaperClipIcon } from '@heroicons/react/solid';
import React, {useContext} from 'react';
import Banner from './Banner';
import LineChart from '../charts/LineChart'
import ResultContext from "../contexts/ResultContext";
import {AuthContext} from "../contexts/AuthContext";
import {useTranslation} from "react-i18next";
import axios from "../api/axios";
import {toast} from "react-toastify";

const Result = () => {
  const { t } = useTranslation();
  const {result, adv} = useContext(ResultContext);
  const { user } = useContext(AuthContext);

  const highRiskResultData = {
    testType: t("S06.R11"),
    riskPrediction: "HIGH",
    riskPrediction_id: 'S06:R131a',
    description: t("S06.R141a"),
    desc_id: "S06:R141a",
    recommendedActions: [
      {
        text: t("S06.R161a"),
        text_id: 'S06:R161a',
        outline: true,
      },
      {
        text: t("S06.R162a"),
        text_id: 'S06:R162a',
        outline: false,
      },
      {
        text: t("S06.R163a"),
        text_id: 'S06:R163a',
        outline: false,
      },
    ]
  };

  const lowRiskResultData = {
    testType: "Patient survey on myopia",
    riskPrediction: "LOW",
    riskPrediction_id: 'S06:R131b',
    description: t("S06.R141b"),
    desc_id: "S06:R141b",
    recommendedActions: [
      {
        text: t("S06.R161b"),
        text_id: 'S06:R161b',
        outline: false,
      },
      {
        text: t("S06.R162b"),
        text_id: 'S06:R162b',
        outline: true,
      },
      {
        text: t("S06.R163b"),
        text_id: 'S06:R163b',
        outline: false,
      },
    ]
  };

  const advanced = {
    testType: t("S06.R121a"),
    testType_id: 'S06:R121a',
    title: t("S06.R1a"),
    title_id: 'S06:R1a',
  };

  const effortless = {
    testType: t("S06.R121b"),
    testType_id: 'S06:R121b',
    title: t("S06.R1b"),
    title_id: 'S06:R1b',
  };

  const resultData = result.Results ? highRiskResultData : lowRiskResultData;
  const type = adv ? advanced : effortless
  const downloadPdf = () => {
    axios.get(result.File)
      .then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'result_myopia_test.pdf');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        toast.error('Server error, try again later.');
      });
  }
  return (
    <div className="bg-white">
      {user.is_debug_account ?
          <div className="site-id">
            S06
          </div>
          : null}
      <Banner />
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
            {type.title}
            {user.is_debug_account && (
                <span className='tooltip-text'>
                  {type.title_id}
                </span>
            )}
          </p>
        </div>
        <div className="max-w-7xl mx-auto mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R12")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                  S06:R12
                </span>
                )}
              </dt>
              <dd className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {type.testType}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      {type.testType_id}
                    </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R13")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      S06:R13
                    </span>
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {
                  resultData.riskPrediction === 'HIGH' && <span className={`text-red-500 font-bold ${user.is_debug_account ? 'tooltip' : ''}`}>
                      {t("S06.R131a")}
                      {user.is_debug_account && (
                          <span className='tooltip-text'>
                            {resultData.riskPrediction_id}
                          </span>
                      )}
                  </span>
                }
                {
                  resultData.riskPrediction === 'LOW' && <span className={`text-green-500 font-bold ${user.is_debug_account ? 'tooltip' : ''}`}>
                      {t("S06.R131b")}
                      {user.is_debug_account && (
                          <span className='tooltip-text'>
                            {resultData.riskPrediction_id}
                          </span>
                      )}
                  </span>
                }
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R14")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                            S06:R14
                          </span>
                )}
              </dt>
              <dd className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ${user.is_debug_account ? 'tooltip' : ''}`}>
              {resultData.description}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      {resultData.desc_id}
                    </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R15")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      S06:R15
                    </span>
                )}
              </dt>
              <dd className="mt-1 sm:mt-0 sm:col-span-2">
                <LineChart />
              </dd>
            </div>
            {adv ? null:(
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R16")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      S06:R16
                    </span>
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="list-disc ml-5">
                  {
                    resultData.recommendedActions.map((action, index) => {
                      let styling = "";
                      if (action.outline) {
                        if (resultData.riskPrediction === 'HIGH') {
                          styling = "font-bold";
                        } else {
                          styling = "font-bold";
                        }
                      }
                      return <li key={index} className={styling}>
                        <div className={`${user.is_debug_account ? 'tooltip' : ''}`}>
                        {action.text}
                          {user.is_debug_account && (
                              <span className='tooltip-text'>
                                {action.text_id}
                              </span>
                          )}
                        </div>
                      </li>;
                    })
                  }
                </ul>
              </dd>
            </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className={`text-sm font-medium text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>
                {t("S06.R17")}
                {user.is_debug_account && (
                    <span className='tooltip-text'>
                      S06:R17
                    </span>
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 flex-1 w-0 truncate">result_myopia_test.pdf</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <button onClick={downloadPdf} className="font-medium text-indigo-700 hover:text-indigo-500">
                        {t("S06.R18")}
                      </button>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Result;
