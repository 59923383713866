import React, {Fragment, useState} from "react";
import { useTranslation } from "react-i18next";
import { Popover, Transition } from "@headlessui/react";
import { FlagIcon } from 'react-flag-kit';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  const getFlag = (lang) => {
    switch (lang) {
      case 'gb':
        return <FlagIcon code="GB" size={24} className="inline-block mx-1"/>;
      case 'pl':
        return <FlagIcon code="PL" size={24} className="inline-block mx-1"/>;
      case 'ir':
        return <FlagIcon code="IR" size={24} className="inline-block mx-1"/>;
      case 'fi':
        return <FlagIcon code="FI" size={24} className="inline-block mx-1"/>;
      case 'pt':
        return <FlagIcon code="PT" size={24} className="inline-block mx-1"/>;
      default:
        return <FlagIcon code={(localStorage.getItem('i18nextLng')).slice(0, 2).toUpperCase()} size={24} className="inline-block mx-1"/>;
    }
  }

  return (
    <Popover className="relative">
        <>
          <Popover.Button className='mx-3'>
            <div className="flex items-center h-8 w-8 animation-menu-button">
              {getFlag(selectedLanguage)}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-48 max-w-md sm:px-0">
              <Transition>
                <div className="absolute z-10 bg-white py-1 px-2 flex flex-col border border-indigo-200 rounded-md">
                  <button className="animation-menu-button text-left mx-1" type="button" onClick={() => changeLanguage("gb")}>
                    {getFlag('gb')} English
                  </button>
                  <button className="animation-menu-button text-left mx-1" type="button" onClick={() => changeLanguage("pl")}>
                    {getFlag('pl')} Polish
                  </button>
                  <button className="animation-menu-button text-left mx-1" type="button" onClick={() => changeLanguage("ir")}>
                    {getFlag('ir')} Persian
                  </button>
                  <button className="animation-menu-button text-left mx-1" type="button" onClick={() => changeLanguage("fi")}>
                    {getFlag('fi')} Finnish
                  </button>
                  <button className="animation-menu-button text-left mx-1" type="button" onClick={() => changeLanguage("pt")}>
                    {getFlag('pt')} Portuguese
                  </button>
                </div>
              </Transition>
            </Popover.Panel>
          </Transition>
        </>
    </Popover>
  );
};

export default LanguageSelector;
