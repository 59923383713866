import React, { useContext } from "react";
import {FormContext} from "../../contexts/FormContext";
import Personal from "./Forms/Personal";
import FamilyAndLifestyle from "./Forms/FamilyAndLifestyle";
import EyeRefraction from "./Forms/EyeRefraction";
import EyeKeratometry from "./Forms/EyeKeratometry";
import EyeVisualAcuity from "./Forms/EyeVisualAcuity";
import EyeOther from "./Forms/EyeOther";
function Step() {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Personal />;
      break;
    case 1:
      stepContent = <FamilyAndLifestyle />;
      break;
    case 2:
      stepContent = <EyeRefraction />;
      break;
    case 3:
      stepContent = <EyeVisualAcuity />;
      break;
    case 4:
      stepContent = <EyeKeratometry />;
      break;
    case 5:
      stepContent = <EyeOther />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default Step;