import {useContext, useEffect} from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { AuthContext } from "../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";


export default function ResetPwdConfirm() {
    const { uid, token } = useParams();
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();
    const CONFIRM_URL = "password/reset/confirm/";

    const schema = Yup.object().shape({
        new_password1: Yup.string()
            .min(8, "Minimum 8 characters")
            .required('Password is a required field'),
        new_password2: Yup.string()
            .min(8, "Minimum 8 characters")
            .required('Password is a required field')
            .oneOf([Yup.ref('new_password1'), null], 'Passwords must match'),
    })

    useEffect(() => {
        if (user.email) {
            navigate('http://localhost:3000/');
        }
    });

    return (
        <>
            <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Set your new password</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={{
                                new_password1:'',
                                new_password2:'',
                                uid:uid,
                                token:token,
                            }}
                            onSubmit={async (values) =>{
                                axios.post(CONFIRM_URL, values)
                                    .then((res) => {
                                        if (res.status === 200) {
                                            toast.success('Password has been changed.')
                                            setTimeout(() => {
                                                navigate("/login");
                                            }, 3000);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error.response.data)
                                        let error_msg = "";
                                        Object.keys(error.response.data).forEach(function (e) {
                                            error_msg += error.response.data[e][0];
                                        });
                                        if(error_msg === 'Invalid value')
                                        {
                                            toast.error('Authorization error. Start the reset process again. ')
                                            setTimeout(() => {
                                                navigate("/password-reset");
                                            }, 3000);
                                        } else {
                                            toast.error(error_msg);
                                        }
                                    });
                            }}
                            validationSchema={schema}
                        >
                            {({errors, touched }) => (
                                <Form className="space-y-6">
                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            New password
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id='new_password1'
                                                name='new_password1'
                                                type='password'
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {errors.new_password1 && touched.new_password1 ? (
                                                <div className='text-red-700'>{errors.new_password1}</div>
                                            ): null }
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="repeatPassword" className="block text-sm font-medium text-gray-700">
                                            Repeat new password
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id='new_password2'
                                                name='new_password2'
                                                type='password'
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {errors.new_password2 && touched.new_password2 ? (
                                                <div className='text-red-700'>{errors.new_password2}</div>
                                            ): null }
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="captcha" className="block text-sm font-medium text-gray-700">
                                            Captcha
                                        </label>
                                        <div className="bg-gray-100 rounded-lg px-4 py-16 sm:p-16">
                                            {/* Some diagram */}
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                                )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
