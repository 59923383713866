import {Field, Form, Formik} from "formik";
import React, {useContext} from "react";
import {FormContext} from "../../../contexts/FormContext";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";

function EyeVisualAcuity() {
  const {t} = useTranslation();
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} = useContext(FormContext);
  const metricOptions = [
    {value: 0.10, foot: "20/200", metre: '6/60', decimal: '0.10', logmar: '1.00'},
    {value: 0.125, foot: "20/160", metre: '6/48', decimal: '0.125', logmar: '0.90'},
    {value: 0.16, foot: "20/125", metre: '6/38', decimal: '0.16', logmar: '0.80'},
    {value: 0.20, foot: "20/100", metre: '6/30', decimal: '0.20', logmar: '0.70'},
    {value: 0.25, foot: "20/80", metre: '6/24', decimal: '0.25', logmar: '0.60'},
    {value: 0.32, foot: "20/63", metre: '6/19', decimal: '0.32', logmar: '0.50'},
    {value: 0.4, foot: "20/50", metre: '6/15', decimal: '0.40', logmar: '0.40'},
    {value: 0.5, foot: "20/40", metre: '6/12', decimal: '0.50', logmar: '0.30'},
    {value: 0.63, foot: "20/32", metre: '6/9.5', decimal: '0.63', logmar: '0.20'},
    {value: 0.8, foot: "20/25", metre: '6/7.5', decimal: '0.80', logmar: '0.10'},
    {value: 1, foot: "20/20", metre: '6/6', decimal: '1.00', logmar: '0.00'},
    {value: 1.25, foot: "20/16", metre: '6/4.8', decimal: '1.25', logmar: '-0.10'},
    {value: 1.6, foot: "20/12.5", metre: '6/3.8', decimal: '1.60', logmar: '-0.20'},
    {value: 2, foot: "20/10", metre: '6/3', decimal: '2.00', logmar: '-0.30'},
  ]
  const initialValues = {
    metric: formData.metric ?? 'foot',
    ucva_l: formData. ucva_l ?? null,
    ucva_r: formData.ucva_r ?? null,
    bcva_l: formData.bcva_l ?? null,
    bcva_r: formData.bcva_r ?? null
  }
  const previousStep = () => {
    setActiveStepIndex(activeStepIndex-1);
  }
  return (
    <div className="mx-10">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = {...formData, ...values};
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({setValues, values}) => (
          <Form autoComplete='off'>
            <div className="text-center">
              <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
                {t("S03.S020")}
                <div className="text-sm text-gray-500">Optional</div>
              </p>
            </div>
            <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
              <div className="w-full">
                <button type='button' onClick={() => {
                  setValues(initialValues)
                }} className="float-right">
                  <TrashIcon className="survey-trash-icon"/>
                </button>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  Select a metric for the following data
                </label>
                <Field
                  className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 inline-block'
                  id='metric'
                  name='metric'
                  as='select'
                >
                  <option value='foot'>Foot</option>
                  <option value='metre'>Metre</option>
                  <option value='decimal'>Decimal</option>
                  <option value='logmar'>LogMAR</option>
                </Field>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'></label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Right eye
                  </div>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Left eye
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  1. Uncorrected visual acuity
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='ucva_r'
                    name='ucva_r'
                    as='select'
                  >
                    {metricOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {values.metric === 'foot' && option.foot}
                        {values.metric === 'metre' && option.metre}
                        {values.metric === 'decimal' && option.decimal}
                        {values.metric === 'logmar' && option.logmar}
                      </option>
                    ))}
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='ucva_l'
                    name='ucva_l'
                    as='select'
                  >
                    {metricOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {values.metric === 'foot' && option.foot}
                        {values.metric === 'metre' && option.metre}
                        {values.metric === 'decimal' && option.decimal}
                        {values.metric === 'logmar' && option.logmar}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 text-left font-bold w-1/2 inline-block'>
                  2. Best corrected visual acuity
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='bcva_r'
                    name='bcva_r'
                    as='select'
                  >
                    {metricOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {values.metric === 'foot' && option.foot}
                        {values.metric === 'metre' && option.metre}
                        {values.metric === 'decimal' && option.decimal}
                        {values.metric === 'logmar' && option.logmar}
                      </option>
                    ))}
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='bcva_l'
                    name='bcva_l'
                    as='select'
                  >
                    {metricOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {values.metric === 'foot' && option.foot}
                        {values.metric === 'metre' && option.metre}
                        {values.metric === 'decimal' && option.decimal}
                        {values.metric === 'logmar' && option.logmar}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
            <div className='text-right mt-2 mb-5 mr-2'>
              <button className="survey-back-btn" onClick={previousStep}>
                {t("S03.S211")}
              </button>
              <button className="survey-next-btn">
                {t("S03.S212")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EyeVisualAcuity;