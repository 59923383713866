import axios from "../api/axios"
import {toast, ToastContainer} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {AuthContext} from "../contexts/AuthContext";
import {useContext} from "react";
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const NEWS_URL = '/newsletter/'
    const { t } = useTranslation();
    const { user } = useContext(AuthContext)
    return (
        <footer className="pt-36 bg-white" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="bg-gray-800 flex flex-col justify-center items-center mx-auto px-2 py-10">
                <div>
                    <div className="mt-8">
                        <h3 className={`text-sm font-semibold text-gray-400 tracking-wider uppercase ${user.is_debug_account ? 'tooltip' : ''}`}>
                            {t("H.F1")}
                            {user.is_debug_account && (
                                <span className='tooltip-text'>
                                  H:F1
                                </span>
                            )}
                        </h3>
                        <p className={`mt-4 text-base text-gray-300 ${user.is_debug_account ? 'tooltip block' : ''}`}>
                            {t("H.F2")}
                            {user.is_debug_account && (
                                <span className='tooltip-text'>
                                  H:F2
                                </span>
                            )}
                        </p>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            onSubmit={async (values) => {
                                axios.post(NEWS_URL, values)
                                    .then((res) => {
                                        if (res.status === 200) {
                                            toast.success(`You are in write to newsletter.`);
                                        }
                                    })
                                    .catch(() => {
                                        toast.error('Error! Try again later.')
                                    })
                            }}
                        >
                            <Form className="mt-4 sm:flex sm:max-w-md">
                                <label htmlFor="email-address" className="sr-only">
                                    {t("email")}
                                </label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                    placeholder={t("your-email")}
                                />
                                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                    <button
                                        type="submit"
                                        className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                                    >
                                        {t("subscribe")}
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                    <p className="text-base text-gray-400 md:mt-0 md:order-1">
                        &copy; Myopia Risk Calculator (MRC) - Copyright 2023
                    </p>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </footer>
    )
}
