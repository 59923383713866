import {createContext, useState} from "react";

export const FormContext = createContext()

export const FormContextProvider = ({ children }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({
    ucva_l: null,
    ucva_r: null,
    bcva_l: null,
    bcva_r: null,
    K1D_l: null,
    K1D_r: null,
    K11_l: null,
    K11_r: null,
    K2D_l: null,
    K2D_r: null,
    K22_l: null,
    K22_r: null,
    AL1_l: null,
    AL1_r: null,
    ACD_l: null,
    ACD_r: null,
    NearPointCM_l: null,
    NearPointCM_r: null,
    AmpCM_l: null,
    AmpCM_r: null,
    AST1_l: null,
    AST1_r: null,
    AmpD_l: null,
    AmpD_r: null,
    AD1_l: null,
    AD1_r: null
  });
  return (
    <FormContext.Provider value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
      { children }
    </FormContext.Provider>
  )
}

export default FormContextProvider;