import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../contexts/AuthContext";

function About() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  return (
    <div className="bg-white">
      {user.is_debug_account ?
        <div className="site-id">
          S01
        </div>
        : null}
      <div className="p-4 text-center max-w-7xl mx-auto">
        <h1 className={`mt-6 text-center text-3xl font-extrabold text-gray-900 mb-4 ${user.is_debug_account ? 'tooltip' : ''}`}>
          {t("S01.S1")}
          {user.is_debug_account && (
              <span className='tooltip-text'>
                                  S01:S1
              </span>
          )}
        </h1>
        <p className={`leading-relaxed mb-4 ${user.is_debug_account ? 'tooltip' : ''}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut felis dui, imperdiet a pretium a, accumsan nec augue. Duis eget nunc dictum, ullamcorper orci a, tincidunt sem. Etiam vestibulum leo vitae scelerisque semper. Morbi laoreet egestas massa, in pellentesque massa malesuada at. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean a fermentum nibh, eget rutrum ante. Morbi fermentum nibh dolor, eu elementum massa sollicitudin at. Nam commodo ac nibh ut egestas. Phasellus scelerisque tellus ac sapien mattis, non condimentum odio vehicula. Proin placerat pulvinar tellus in consectetur. Nullam elit purus, lobortis id cursus ullamcorper, lacinia ut risus. Nam lorem est, interdum nec ante vitae, lacinia ullamcorper eros.

          Nulla facilisi. Aenean pretium libero sit amet libero finibus interdum. Aliquam vestibulum rutrum velit, id commodo est egestas ac. Nullam suscipit, nibh blandit molestie tincidunt, leo nisl cursus ex, sit amet consectetur mi est et magna. Vestibulum mollis ultricies arcu a dignissim. Mauris sit amet iaculis dui. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce tempor diam non velit scelerisque tempor. Curabitur enim mi, viverra quis finibus eu, rutrum ac purus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas placerat ex non mi hendrerit rhoncus.
          {user.is_debug_account && (
              <span className='tooltip-text'>
                                  S01:S11
                              </span>
          )}
        </p>
        <p className={`leading-relaxed mb-4 ${user.is_debug_account ? 'tooltip' : ''}`}>
          Aenean scelerisque justo vitae dui posuere, in placerat ante aliquam. Donec hendrerit mi bibendum nibh ullamcorper porttitor. Mauris ac fringilla leo. Nunc aliquam urna sem, id tempor risus rhoncus vitae. Cras at viverra enim. Etiam a ligula non lacus blandit ultricies vel vel arcu. Phasellus at vehicula ex, a egestas lorem. Phasellus vestibulum tortor vitae enim dictum viverra. Vestibulum quis accumsan sapien. Nam tincidunt euismod sem, quis interdum lorem sollicitudin aliquam. Aenean consequat facilisis est.

          Proin tristique a ex eget interdum. Vestibulum ultrices, ex a tempus varius, ex odio maximus sem, ut tristique turpis dui quis elit. Sed vel lacus vel libero volutpat faucibus. Quisque rhoncus enim ante, sed tempus libero sollicitudin a. Pellentesque egestas ex eu lectus tristique, vitae commodo odio faucibus. Aliquam tristique metus lacus, eu condimentum tellus sodales eget. Etiam vitae dignissim orci. Nam pharetra ante eget libero iaculis, ac semper nunc aliquet. Integer sagittis ipsum ac est vehicula, id fermentum nulla vehicula.

          Sed ut vestibulum turpis. Integer non aliquam ex, sed semper nisi. Integer leo turpis, tempus et enim at, ornare sollicitudin urna. Aliquam faucibus mattis tellus a dictum. Duis rutrum et est et hendrerit. Aenean pharetra pretium dui a convallis. Pellentesque vel euismod justo.
          {user.is_debug_account && (
              <span className='tooltip-text'>
                                  S01:S12
                              </span>
          )}
        </p>
        <p className={`leading-relaxed mb-4 ${user.is_debug_account ? 'tooltip' : ''}`}>
          Cras tincidunt lacinia mi vitae rhoncus. Vestibulum sagittis velit orci, vel iaculis mauris finibus eget. Vivamus ut suscipit nisl. Vivamus pretium mauris ut enim molestie, non congue enim tincidunt. Phasellus aliquam, tellus vitae tincidunt maximus, purus urna volutpat nunc, sed sodales ligula orci a ante. Phasellus quis purus elit. Praesent vestibulum sit amet magna id pulvinar. Nunc tincidunt sagittis massa ut dignissim. In ut enim ipsum. Nunc non nunc at massa posuere gravida.

          Praesent consequat enim dapibus dui blandit condimentum. Pellentesque commodo eros sed sapien pretium, quis porttitor lorem tristique. Donec eu mauris eu dui sollicitudin euismod. Etiam pellentesque velit at felis pellentesque molestie. Nulla non ultrices purus, non ornare dolor. Donec molestie sed risus sit amet efficitur. Praesent non ornare enim, ac hendrerit magna. Nam nec semper libero. Nam volutpat tortor ex, sit amet volutpat urna blandit eleifend. Donec elementum mollis consequat.

          Nunc pretium dolor quis nulla egestas, ac bibendum nibh molestie. Ut laoreet risus non magna consequat, id lacinia eros semper. Pellentesque egestas, odio feugiat luctus venenatis, diam libero commodo arcu, ut scelerisque dolor neque eu sem. Fusce ac nunc fermentum, consequat nibh quis, egestas diam. Nulla facilisi. Maecenas volutpat aliquet velit, et dapibus odio hendrerit sit amet. Integer pulvinar at neque eget auctor. Nulla consequat ultrices sem at viverra. Cras mattis sollicitudin auctor. Vivamus interdum dolor eget metus dignissim ultricies. Suspendisse lobortis accumsan eros, quis ultrices velit tempor in. Etiam tortor est, egestas eu convallis nec, hendrerit vel orci. Duis eu tristique nulla.
          {user.is_debug_account && (
              <span className='tooltip-text'>
                                  S01:S13
                              </span>
          )}
        </p>
      </div>
    </div>
  );
}

export default About;