import {Field, Form, Formik} from "formik";
import React, {useContext} from "react";
import {FormContext} from "../../../contexts/FormContext";
import * as yup from "yup";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

function Personal() {
  const {t} = useTranslation();
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} =
    useContext(FormContext);

  const years = Array.from({length: 10}, (_, i) => i + 2014);
  const months = [
    {name: t("S03.S001"), value: 1},
    {name: t("S03.S002"), value: 2},
    {name: t("S03.S003"), value: 3},
    {name: t("S03.S004"), value: 4},
    {name: t("S03.S005"), value: 5},
    {name: t("S03.S006"), value: 6},
    {name: t("S03.S007"), value: 7},
    {name: t("S03.S008"), value: 8},
    {name: t("S03.S009"), value: 9},
    {name: t("S03.S010"), value: 10},
    {name: t("S03.S011"), value: 11},
    {name: t("S03.S012"), value: 12},
  ];
  const personalData = [
    {name: 'Year', display: t("S03.S111"), type: 'select', options: years},
    {name: 'Month', display: t("S03.S112"), type: 'select', options: months},
    {
      name: 'sex',
      display: t("S03.S113"),
      type: 'radio',
      options: [{name: t("S03.S113a"), value: 'Male'}, {name: t("S03.S113b"), value: "Female"}]
    },
    {
      name: 'Region',
      display: t("S03.S114"),
      type: 'radio',
      options: [{name: t("S03.S114a"), value: "Urban"}, {name: t("S03.S114b"), value: "Rural"}]
    },
    {name: 'Height_x', display: t("S03.S115"), type: 'number', unit: 'select', options: ['cm', 'in']},
    {name: 'Weight', display: t("S03.S116"), type: 'number', unit: 'select', options: ['kg', 'lb']},
  ];
  const ValidationSchema = yup.object().shape({
    Year: Yup.number().required("This field is required"),
    Month: Yup.number().required("This field is required"),
    sex: Yup.string().required("This field is required"),
    Region: Yup.string().required("This field is required"),
    Height_x: Yup.number()
      .required("This field is required")
      .min(90)
      .max(180),
    Height_xUnit: Yup.string().required("This field is required"),
    Weight: Yup.number()
      .required("This field is required")
      .min(15)
      .max(120),
    WeightUnit: Yup.string().required("This field is required"),
  });
  const initialValues = {
    Year: formData.Year ?? 2014,
    Month: formData.Month ?? 1,
    sex: formData.sex ?? '',
    Region: formData.Region ?? '',
    Height_x: formData.Height_x ?? '',
    Height_xUnit: formData.Height_xUnit ?? 'cm',
    Weight: formData.Weight ?? '',
    WeightUnit: formData.WeightUnit ?? 'kg'
  };
  return (
    <div className="mx-10">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = Object.assign({}, formData, values);
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({touched, errors, setValues}) => (
          <Form autoComplete='off'>
            <div className="text-center">
              <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
                {t("S03.S017")}
              </p>
            </div>
            <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
              <div className="w-full">
                <button type='button' onClick={()=>setValues(initialValues)} className="float-right">
                  <TrashIcon className="survey-trash-icon"/>
                </button>
              </div>
              {personalData.map((question, index1) => (
                <div className="w-full flex p-2" key={index1}>
                  <label className="w-1/3 my-2 font-bold pr-4">{index1 + 1}. {question.display}</label>
                  <div className="w-2/3">
                    {question.type === 'select' && (
                      <div>
                        <Field as="select" name={question.name}
                          className="bg-gray-200 examination-section-table rounded-lg p-2 mt-2 w-full">
                          {question.options.map((option) => (
                            <option key={option.value} value={question.name === 'Month' ? option.value : option}>
                              {option.name || option}
                            </option>
                          ))}
                        </Field>
                      </div>
                    )}
                    {question.type === 'number' && (
                      <div className="flex">
                        <Field name={question.name} id={question.name}
                          className="examination-section-table focus:outline-none focus:shadow-outline bg-gray-200 rounded-lg p-2 mr-2 w-2/3"
                          type="number" placeholder={question.placeholder} />
                        {question.unit === 'select' && (
                          <Field as="select" name={question.name + 'Unit'} className="bg-gray-200 rounded-lg p-2 w-1/3">
                            {question.options.map((option) => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </Field>
                        )}
                      </div>
                    )}
                    {question.type === 'radio' && (
                      <div className="flex flex-wrap">
                        {question.options.map(option => (
                          <label key={option.value} className="text-center mx-5 -translate-x-5 translate-y-2">
                            <Field type="radio" name={question.name} value={option.value} className="form-radio text-indigo-600" />
                            {option.name}
                          </label>
                        ))}
                      </div>
                    )}
                    {errors[question.name] && touched[question.name] && (
                      <div className='text-red-700'>
                        {errors[question.name]}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className='text-right mt-2 mb-2 mr-2'>
              <button className="survey-next-btn">
                {t("S03.S212")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Personal;