import React, {useContext, useEffect, useState} from "react";
import axios from "../../api/axios";
import {AuthContext} from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import Modal from "react-modal";
import {XCircleIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function EditProfile() {
    const { user } = useContext(AuthContext);
    const [surveys, setSurveys] = useState([]);
    const USER_URL = '/user/' + user.uuid;
    const PASSWORD_CHANGE_URL = '/password/change/'
    const [city, setCity] = useState('');
    const { t, i18n } = useTranslation();
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const citySchema = Yup.object().shape({
        city: Yup.string()
            .required("Can't be null"),
    })
    const handleOpenModal = (survey) => {
        setCurrentSurvey(survey);
    };
    const handleCloseModal = () => {
        setCurrentSurvey(null);
    };
    const changePasswordSchema = Yup.object().shape({
        old_password: Yup.string()
            .required("Can't be null"),
        new_password1: Yup.string()
            .min(8, "Minimum 8 characters")
            .required("This field is required"),
        new_password2: Yup.string()
            .min(8, "Minimum 8 characters")
            .required("This field is required")
            .oneOf([Yup.ref('new_password1'), null], 'Passwords must match'),
    })
    const disableAttributes = ['id', 'user', 'data_examination', 'results']
    const getHistoryTitle = (dateTimeString, resultSurvey) => {
        const date = new Date(dateTimeString);
        const dateString = date.toLocaleDateString();
        const timeString = date.toLocaleTimeString();
        const prediction = resultSurvey ? 'HIGH' : 'LOW'
        return `${dateString} ${timeString} ${prediction}`;
    };
    useEffect(() => {
        axios.get(USER_URL)
            .then(res => {
                setCity(res.data.city);
            })
        axios.get('/exam')
          .then(res => {
              setSurveys(res.data)
          })
    },[]);
    return (
        <>
            <div className="flex flex-wrap justify-center mx-auto max-w-7xl">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">Surveys history</h2>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md rounded-lg overflow-hidden">
                    {surveys.map((survey, index) => (
                        <div key={index} className={`sm:mx-auto shadow h-12 font-bold sm:w-full sm:max-w-md my-4`}>
                        <div onClick={() => handleOpenModal(survey)} className={`w-full h-full text-center cursor-pointer bg-white hover:bg-gray-200 rounded-lg flex items-center justify-center`} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <span className="mr-2">{index + 1}.</span>
                            <span className={`${survey.results ? 'text-red-500' : 'text-green-600'}`}>{getHistoryTitle(survey.data_examination, survey.results)}</span>
                        </div>
                        {currentSurvey === survey && (
                            <Modal style={customStyles} isOpen={currentSurvey !== null} onRequestClose={handleCloseModal}>
                            <button type="button" onClick={handleCloseModal} className="float-right">
                                <XCircleIcon className="h-9 w-9 text-center text-red-600 hover:text-red-800 duration-150 hover:scale-110"></XCircleIcon>
                            </button>
                            {currentSurvey && (
                                <table>
                                <tbody>
                                    {Object.entries(currentSurvey).map(([item, value]) => (
                                    !disableAttributes.includes(item) && value && (
                                        <tr key={item}>
                                        <td className="pr-8">{t("SurveyHistory." + item)}</td>
                                        {i18n.exists("SurveyHistory." + (value.toString())) ? (
                                            <td>{t("SurveyHistory." + (value.toString()))}</td>
                                        ) : (
                                            <td>{value.toString()}</td>
                                        )}
                                        </tr>
                                    )
                                    ))}
                                </tbody>
                                </table>
                            )}
                            </Modal>
                        )}
                        </div>
                    ))}
                    </div>

                </div>

                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">Edit your account</h2>
                    </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-2 px-4 shadow sm:rounded-lg sm:px-10">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mb-3 text-center text-1xl font-extrabold text-gray-900">Personal information</h2>
                        </div>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize
                            initialValues={{
                                city: city,
                            }}
                            onSubmit={async (values) => {
                                axios.patch(USER_URL, values)
                                    .then((res)=>{
                                        if (res.status === 200)
                                            toast.success('Saved!')
                                    })
                                    .catch((error)=>{
                                        toast.error(error.response.data)
                                    })
                            }}
                            validationSchema={citySchema}
                        >
                        {({errors, touched }) => (
                        <Form className="space-y-6">
                            <div>
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <Field
                                        id="city"
                                        name="city"
                                        defaultValue={city}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.city && touched.city ? (
                                        <div className='text-red-700'>{errors.city}</div>
                                    ): null }
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                Save
                                </button>
                            </div>
                        </Form>
                        )}
                        </Formik>
                    </div>
                </div>
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-2 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <h2 className="mb-3 text-center text-1xl font-extrabold text-gray-900">Change password</h2>
                            </div>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                initialValues={{
                                    old_password:'',
                                    new_password1:'',
                                    new_password2:'',
                                }}
                                onSubmit={async (values) => {
                                    console.log(values)
                                    axios.post(PASSWORD_CHANGE_URL, values)
                                        .then((res)=>{
                                            if (res.status === 200) {
                                                toast.success(res.data.detail);
                                                setTimeout(() => {
                                                    window.location.reload();
                                                }, 3000);
                                            }
                                        })
                                        .catch((error)=>{
                                            let error_msg = "";
                                            Object.keys(error.response.data).forEach(function (e) {
                                                error_msg += error.response.data[e];
                                            });
                                            toast.error(error_msg);
                                        })
                                }}
                                validationSchema={changePasswordSchema}
                                >
                                {({errors, touched }) => (
                                <Form className="space-y-6">
                                    <div>
                                        <label 
                                            htmlFor="oldPassword"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Old password
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id="old_password"
                                                name="old_password"
                                                type="password"
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {errors.old_password && touched.old_password ? (
                                                <div className='text-red-700'>{errors.old_password}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label 
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700">
                                            New password
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id="new_password1"
                                                name="new_password1"
                                                type="password"
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {errors.new_password1 && touched.new_password1 ? (
                                                <div className='text-red-700'>{errors.new_password1}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="repeatPassword"
                                            className="block text-sm font-medium text-gray-700">
                                            Repeat new password
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id="new_password2"
                                                name="new_password2"
                                                type="password"
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {errors.new_password2 && touched.new_password2 ? (
                                                <div className='text-red-700'>{errors.new_password2}</div>
                                            ): null }
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}