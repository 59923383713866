import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ExpertSurveyAISelectionForm from "../../components/ExpertSurvey/ExpertSurveyAISelectionForm";
import '../../index.css';

const ExpertSurveyAISelection = props => {
    return (
        <>
            <Header />
            <ExpertSurveyAISelectionForm extended={props.extended} />
            <Footer />
        </>
    );
}

export default ExpertSurveyAISelection;