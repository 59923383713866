import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useContext} from "react";
import {FormContext} from "../../../contexts/FormContext";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import * as Yup from "yup";

function EyeOther() {
  const {t} = useTranslation();
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    AL1_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(18,'Minimal value is 18')
      .max(30, "Maximal value is 30"),
    AL1_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(18,'Minimal value is 18')
      .max(30, "Maximal value is 30"),
    NearPointCM_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(1,'Minimal value is 1')
      .max(3, "Maximal value is 3"),
    NearPointCM_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(1,'Minimal value is 1')
      .max(3, "Maximal value is 3"),
    AmpCM_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(3,'Minimal value is 3')
      .max(15, "Maximal value is 15"),
    AmpCM_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(3,'Minimal value is 3')
      .max(15, "Maximal value is 15"),
    AST1_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(-5,'Minimal value is -5')
      .max(5, "Maximal value is 5"),
    AST1_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(-5,'Minimal value is -5')
      .max(5, "Maximal value is 5"),
    AmpD_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(5,'Minimal value is 5')
      .max(35, "Maximal value is 35"),
    AmpD_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(5,'Minimal value is 5')
      .max(35, "Maximal value is 35"),
    AD1_l: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(2,'Minimal value is 2')
      .max(5, "Maximal value is 5"),
    AD1_r: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value)).nullable()
      .min(2,'Minimal value is 2')
      .max(5, "Maximal value is 5"),
  });

  const initialValues = {
    AL1_l: formData.AL1_l ?? '',
    AL1_r: formData.AL1_r ?? '',
    ACD_l: formData.ACD_l ?? '',
    ACD_r: formData.ACD_r ?? '',
    NearPointCM_l: formData.NearPointCM_l ?? '',
    NearPointCM_r: formData.NearPointCM_r ?? '',
    AmpD_l: formData.AmpD_l ?? '',
    AmpD_r: formData.AmpD_r ?? '',
    AD1_l: formData.AD1_l ?? '',
    AD1_r: formData.AD1_r ?? '',
  }
  const previousStep = () => {
    setActiveStepIndex(activeStepIndex-1);
  }
  return (
    <div className="mx-10">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = {...formData, ...values};
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({setValues}) => (
          <Form autoComplete='off'>
            <div className="text-center">
              <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
                {t("S03.S022")}
                <div className="text-sm text-gray-500">Optional</div>
              </p>
            </div>
            <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
              <div className="w-full">
                <button type='button' onClick={() => {setValues(initialValues)}} className="float-right">
                  <TrashIcon className="survey-trash-icon"/>
                </button>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'></label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Right eye
                  </div>
                  <div className='mx-5 text-center rounded-lg p-2 mt-2 w-1/3 inline-block'>
                    Left eye
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  1. {t("S03.S135d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AL1_r'
                    name='AL1_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AL1_l'
                    name='AL1_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='AL1_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='AL1_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  2. {t("S03.S136d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='ACD_r'
                    name='ACD_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='ACD_l'
                    name='ACD_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='ACD_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='ACD_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  3. {t("S03.S137d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='NearPointCM_r'
                    name='NearPointCM_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='NearPointCM_l'
                    name='NearPointCM_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='NearPointCM_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='NearPointCM_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  4. {t("S03.S140d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AmpD_r'
                    name='AmpD_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AmpD_l'
                    name='AmpD_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='AmpD_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='AmpD_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap mx-2 w-full'>
                <label className='my-2 font-bold w-1/2 inline-block'>
                  5. {t("S03.S141d")}
                </label>
                <div className='flex flex-wrap w-1/2 inline-block'>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AD1_r'
                    name='AD1_r'
                    type='number'
                  >
                  </Field>
                  <Field
                    className='mx-5 bg-gray-200 rounded-lg p-2 mt-2 w-1/3 inline-block'
                    id='AD1_l'
                    name='AD1_l'
                    type='number'
                  >
                  </Field>
                  <div className="flex-1">
                    <div className="text-red-700">
                      <ErrorMessage name='AD1_r' component="div"/>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-red-700 text-right mr-2">
                      <ErrorMessage name='AD1_l' component="div"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-right mt-2 mb-5 mr-2'>
              <button className="survey-back-btn" onClick={previousStep}>
                {t("S03.S211")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EyeOther;