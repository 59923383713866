import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import EffortlessSurvey from "../../components/Survey/EffortlessSurvey";
import '../../index.css';

const EfoSurvey = props => {
    return (
        <>
            <Header />
            <EffortlessSurvey extended={props.extended} />
            <Footer />
        </>
    );
}

export default EfoSurvey;
