import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import '../../index.css';
import ResultContext from "../../contexts/ResultContext";
import {AuthContext} from "../../contexts/AuthContext";

const algorithm_list = [
    {
        name:"Regression",
        islocked: false,
    },   
    {
        name:"Logistic regression",
        islocked: false,
    }, 
    {
        name:"K-nearest neighbors",
        islocked: false,
    }, 
    {
        name:"Support-vector machines",
        islocked: false,
    }, 
    {
        name:"Decision tree",
        islocked: false,
    }, 
    {
        name:"Random forrest",
        islocked: false,
    }, 
    {
        name:"Boosting",
        islocked: false,
    }, 
    {
        name:"Fuzzy logic",
        islocked: false,
    }, 
    {
        name:"Rough sets",
        islocked: false,
    }, 
    {
        name:"Neural networks",
        islocked: false,
    }, 
];

const ExpertSurveyForm = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const { setExpert } = useContext(ResultContext)
    const [buttonC, setButtonC] = React.useState(1)

    const handleSubmit = () => {
        navigate('/medical-survey')
        setExpert(true)
    }

    const choose_algorithms = algorithm_list => {
        return(
            <>
            {
                algorithm_list.map((algorithm) => {
                    return (
                        <button className="survey-ML-btn" 
                            style={{
                                backgroundColor: algorithm.islocked? 'green':'grey'}}  
                                onClick={e => {buttonC===1? setButtonC(2):setButtonC(1);
                                    algorithm.islocked=!algorithm.islocked}}
                        >
                            {
                                algorithm.name
                            }
                        </button>
                    );
                })
            }
            </>
        )
    }

    return (
    <>
        <div>
            {user.is_debug_account ?
              <div className="site-id">
                  S07
              </div>
              : null}
            <div className="text-center">
                <p className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
                    Myopia expert survey
                    {user.is_debug_account && (
                        <span className='tooltip-text'>
                            S07:S3
                        </span>
                    )}
                </p>
                <p className={`mt-4 max-w-2xl text-xl text-gray-500 mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
                    Select your algorithms
                    {user.is_debug_account && (
                        <span className='tooltip-text'>
                            S07:S31
                        </span>
                    )}
                </p>
            </div>
            <div className="border rounded-lg mt-16 mx-10 text-center shadow">
                <div className="mx-auto">
                    <div className="my-2 border-gray-200 rounded-lg p-5 font-bold">
                        <div className="grid grid-cols-3 gap-3">
                            {choose_algorithms(algorithm_list)}
                        </div>
                    </div>
                    <div className="text-mid mt-4 mb-2 mr-2">
                        <button 
                            className="survey-next-btn" 
                            onClick={e => handleSubmit()}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default ExpertSurveyForm;
