import React from "react";
import banner from '../banner.webp';

const Banner = () => {
    return (
        <h2 className="text-base scale-100 text-indigo-600 font-semibold tracking-wide uppercase">
            <img width="7351" height="1282" src={banner} alt="banner"/>
        </h2>
    );
}

export default Banner;
