import React, {useContext} from "react";
import Chart from "chart.js";
import ResultContext from "../contexts/ResultContext";

export default function LineChart() {
  const {result} = useContext(ResultContext)
  const se = result.SE1
  const AgeY = result.AgeY
  const high = [se, se-0.8, se-1.3, se-1.7, se-2.1, se-2.4, se-2.7, se-3, se-3.1]
  const low = [se, se-0.03, se-0.05, se-0.06, se-0.07, se-0.07, se-0.07, se-0.08, se-0.08]
  const highT = [se, se-0.3, se-0.6, se-0.9, se-1.1, se-1.3, se-1.5, se-1.6, se-1.7]
  const lowT = [0]
  const prediction = result.Results ? [high, highT] : [low, lowT]
  React.useEffect(() => {
    const config = {
      type: "line",
      data: {
        labels: [AgeY, AgeY+1, AgeY+2, AgeY+3, AgeY+4, AgeY+5, AgeY+6, AgeY+7, AgeY+8],
        datasets: [
          {
            label: "SE without treatment",
            backgroundColor: "orange",
            borderColor: "orange",
            data: prediction[0],
            fill: false,
          },
          {
            label: "SE with treatment",
            fill: false,
            backgroundColor: "green",
            borderColor: "green",
            data: prediction[1],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "SE Progression - Chart",
          fontColor: "black",
        },
        legend: {
          labels: {
            fontColor: "black",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "black",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Age of the patient (Years)",
                fontColor: "black",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "black",
                zeroLineColor: "green",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                reverse: true,
                fontColor: "black",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Spherical equivalent (D)",
                fontColor: "black",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "white",
                zeroLineColor: "blue",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    const ctx = document.getElementById("line-chart").getContext("2d");
    window.myLine = new Chart(ctx, config);
  }, []);
  
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                SE progression in diopters
              </h6>
              <h2 className="text-white text-xl font-semibold">Values</h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          <div className="relative h-96">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}