import {createContext, useState} from "react";

const ResultContext = createContext();

export function ResultProvider({children}){
    const [result, setResult] = useState({})
    const [expert, setExpert] = useState(false)
    const [adv, setAdv] = useState(false)
    return(
        <ResultContext.Provider value={{ result, setResult, adv, setAdv, expert, setExpert }}>
            {children}
        </ResultContext.Provider>
    );
}

export default ResultContext;
