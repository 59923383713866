import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Result from '../components/Result';
import '../index.css';

const Results = () => {
    return (
        <>
            <Header />
            <Result />
            <Footer />
        </>
    );
}

export default Results;
