import {useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import {AuthContext} from "../contexts/AuthContext";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

export default function Register() {
    const USER_URL = "/register/";

    function calculateAge(birthday) {
        let ageDifMs = Date.now() - birthday;
        let ageDate = new Date(ageDifMs);
        return (ageDate.getUTCFullYear() - 1970);
    }

    const schema = Yup.object().shape({
        email: Yup.string()
            .required("Email is a required field")
            .email("Invalid email format"),
        password1: Yup.string()
            .min(8, "Minimum 8 characters")
            .required('Password is a required field'),
        password2: Yup.string()
            .min(8, "Minimum 8 characters")
            .required('Password is a required field')
            .oneOf([Yup.ref('password1'), null], 'Passwords must match'),
        first_name: Yup.string()
            .required('First name is required')
            .min(3, 'Wrong data')
            .matches(
                /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
                'Name can only contain Latin letters.'
            ),
        last_name: Yup.string()
            .required('Last name is required')
            .min(3,'Wrong data')
            .matches(
                /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
                'Name can only contain Latin letters.'
            ),
        city: Yup.string()
            .required('City is required'),
        terms: Yup.boolean()
            .oneOf([true], "You must accept the terms and conditions."),
        birth_date: Yup.date()
            .required(`Birth date is required`)
            .test("birthday", "Only above 5", function(val) {
            return calculateAge(new Date(val)) > 5;
            }),

    })

    const navigate = useNavigate();
    const { user } = useContext(AuthContext)
    const { t } = useTranslation();

    useEffect(() => {
        if (user.email) {
            navigate('/');
        }
    });

    return (
        <>
            <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t("register-page.title")}</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={{
                                email:'',
                                password1:'',
                                password2:'',
                                first_name:'',
                                last_name:'',
                                city:'',
                                birth_date:'',
                                terms:false,
                            }}
                            onSubmit={async(values) => {
                                const data = {
                                    email: values.email,
                                    password1: values.password1,
                                    password2: values.password2,
                                    first_name: values.first_name,
                                    last_name: values.last_name,
                                    city: values.city,
                                    birth_date: values.birth_date,
                                }
                                axios.post(USER_URL, data)
                                    .then((res) => {
                                        if (res.status === 201) {
                                            toast.success(`Account has been created! Now verify your email.`);
                                        }
                                        setTimeout(() => {
                                            navigate("/login");
                                        }, 6000);
                                    })
                                    .catch((error) => {
                                        let error_msg = "";
                                        Object.keys(error.response.data).forEach(function (e) {
                                            error_msg += error.response.data[e];
                                        });
                                        toast.error(error_msg);
                                    });
                            }}
                                validationSchema={schema}
                            >
                            {({errors, touched }) => (
                                <Form className="space-y-6">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.email")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='email'
                                                name='email'
                                                type='email'
                                            />
                                            {errors.email && touched.email ? (
                                                <div className='text-red-700'>{errors.email}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.password")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='password1'
                                                name='password1'
                                                type='password'
                                            />
                                            {errors.password1 && touched.password1 ? (
                                                <div className='text-red-700'>{errors.password1}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="Repassword" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.repassword")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='password2'
                                                name='password2'
                                                type='password'
                                            />
                                            {errors.password2 && touched.password2 ? (
                                                <div className='text-red-700'>{errors.password2}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.first-name")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='first_name'
                                                name='first_name'
                                            />
                                            {errors.first_name && touched.first_name ? (
                                                <div className='text-red-700'>{errors.first_name}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.last-name")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='last_name'
                                                name='last_name'
                                            />
                                            {errors.last_name && touched.last_name ? (
                                                <div className='text-red-700'>{errors.last_name}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.city")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='city'
                                                name='city'
                                            />
                                            {errors.city && touched.city ? (
                                                <div className='text-red-700'>{errors.city}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                                            {t("register-page.birthdate")}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id='birth_date'
                                                name='birth_date'
                                                type='date'
                                            />
                                            {errors.birth_date && touched.birth_date ? (
                                                <div className='text-red-700'>{errors.birth_date}</div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-1 mx-1">
                                            <label>
                                                <Field
                                                    type='checkbox'
                                                    name='terms'
                                                />
                                                <span className="mx-2 text-sm font-medium text-gray-700">{t("register-page.terms")} <a href="/terms" target="_blank" className='text-red-600'>{t("register-page.termsh")}</a></span>
                                                {errors.terms && touched.terms ? (
                                                    <div className='text-red-700'>{errors.terms}</div>
                                                ): null }
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="captcha" className="block text-sm font-medium text-gray-700">
                                            Captcha
                                        </label>
                                        <div className="bg-gray-100 rounded-lg px-4 py-16 sm:p-16">
                                            {/* Some diagram */}
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t("register-page.signup")}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}