import React, {useContext} from "react";
import '../../index.css';
import Banner from "../Banner";
import axios from "../../api/axios";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import ResultContext from "../../contexts/ResultContext";
import * as Yup from "yup";
import {AuthContext} from "../../contexts/AuthContext";
import {useTranslation} from "react-i18next";

const EFFORTLESS_URL = '/calculator-effortless/'

const EffortlessSurvey = () => {
  const {setResult} = useContext(ResultContext);
  const {user} = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    age: Yup.number()
      .required("This field is required")
      .min(1, 'You must be at least 1 year old'),
    type: Yup.string()
      .required('This field is required'),
    gender: Yup.string()
      .required('This field is required'),
    height: Yup.number()
      .required("This field is required")
      .min(50, 'Minimal height is 50cm')
      .max(200, 'Maximal height is 200cm'),
    se: Yup.number()
      .required('This field is required')
      .min(-10, 'Minimal myopia level is -10 D')
      .max(6, 'Maximal myopia level is 6 D'),
    region: Yup.string()
      .required("This field is required"),
    parents: Yup.string()
      .required("This field is required"),
    near: Yup.number()
      .required('This field is required')
      .min(0, 'Invalid value')
      .max(24, 'Invalid value'),
    outdoor: Yup.number()
      .required('This field is required')
      .min(0, 'Invalid value')
      .max(24, 'Invalid value'),
  })

  return (
    <>
      {user.is_debug_account ?
        <div className="site-id">
          S03
        </div>
        : null}
      <Banner/>
      <div>
        <div className="text-center">
          <p
            className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
            {t("S03.S1")}
            {user.is_debug_account && (
              <span className='tooltip-text'>
                              S03:S1
                            </span>
            )}
          </p>
          <p
            className={`mt-4 max-w-2xl text-xl text-gray-500 mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
            {t("S03.S11")}
            {user.is_debug_account && (
              <span className='tooltip-text'>
                              S03:S11
                            </span>
            )}
          </p>
        </div>
        <div className="border rounded-lg mt-16 mx-10 p-2 text-center shadow">
          <Formik
            initialValues={{
              type: '1',
              age: '',
              gender: '1',
              height: '',
              se: '',
              region: '1',
              parents: '1',
              near: '',
              outdoor: '',
            }}
            onSubmit={async (values) => {
              const data = {
                type_machine_learning: values.type,
                AgeY: values.age,
                sex: values.gender,
                Height_x: values.height,
                SE1: values.se,
                Region: values.region,
                ParentaL_myopia: values.parents,
                p1_totalnearH: values.near,
                p1_outdoorH: values.outdoor,
              }
              axios.post(EFFORTLESS_URL, data)
                .then((res) => {
                  if (res.status === 201) {
                    setResult(res.data)
                    navigate("/results")
                  }
                })
                .catch(() => {
                  toast.error('Server error');
                });
            }}
            validationSchema={schema}
          >
            {({errors, touched}) => (
              <Form>
                <div className="text-left mt-10">
                  <ol className="list-decimal list-inside mx-4 md:mx-10">
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S111")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S111
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='age'
                        name='age'
                        type='number'
                        min='1'
                      />
                      {errors.age && touched.age ? (
                        <div className='text-red-700'>{errors.age}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S112")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S112
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='gender'
                        name='gender'
                        as='select'
                        type='number'
                      >
                        <option value='1'>{t("S03.S112a")}</option>
                        <option value='2'>{t("S03.S112b")}</option>
                      </Field>
                      {errors.gender && touched.gender ? (
                        <div className='text-red-700'>{errors.gender}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S113")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S113
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='height'
                        name='height'
                        type='number'
                        min='1'
                        placeholder={t("S03.S113d")}
                      />
                      {errors.height && touched.height ? (
                        <div className='text-red-700'>{errors.height}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S114")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S114
                                                    </span>
                        )}
                      </div>

                      <Field
                        className='survey-input-control'
                        id='se'
                        name='se'
                        type='number'
                        placeholder={t("S03.S114d")}
                      />
                      {errors.se && touched.se ? (
                        <div className='text-red-700'>{errors.se}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S115")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S115
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='region'
                        name='region'
                        type='number'
                        as='select'
                      >
                        <option value='1'>{t("S03.S115a")}</option>
                        <option value='2'>{t("S03.S115b")}</option>
                      </Field>
                      {errors.region && touched.region ? (
                        <div className='text-red-700'>{errors.region}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S116")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S116
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='parents'
                        name='parents'
                        as='select'
                      >
                        <option value='1'>{t("S03.S116a")}</option>
                        <option value='2'>{t("S03.S116b")}</option>
                        <option value='0'>{t("S03.S116c")}</option>
                      </Field>
                      {errors.parents && touched.parents ? (
                        <div className='text-red-700'>{errors.parents}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">
                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S117")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S117
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='near'
                        name='near'
                        type='number'
                      />
                      {errors.near && touched.near ? (
                        <div className='text-red-700'>{errors.near}</div>
                      ) : null}
                    </li>
                    <li className="my-2 border border-gray-200 rounded-lg p-5 font-bold">

                      <div className={`${user.is_debug_account ? 'tooltip' : 'inline-block'}`}>
                        {t("S03.S118")}
                        {user.is_debug_account && (
                          <span className='tooltip-text'>
                                                      S03:S118
                                                    </span>
                        )}
                      </div>
                      <Field
                        className='survey-input-control'
                        id='outdoor'
                        name='outdoor'
                        type='number'
                      />
                      {errors.outdoor && touched.outdoor ? (
                        <div className='text-red-700'>{errors.outdoor}</div>
                      ) : null}
                    </li>
                  </ol>
                </div>
                <div className='text-right mt-4 mb-2 mr-2'>
                  <button className="survey-result-btn">
                    {t("S03.S119")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default EffortlessSurvey;
