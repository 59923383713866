import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useContext, useState} from "react";
import {FormContext} from "../../../contexts/FormContext";
import * as yup from "yup";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

function FamilyAndLifestyle() {
  const {t} = useTranslation();
  const [bottomErrorMessage, setBottomErrorMessage] = useState("");
  const {activeStepIndex, setActiveStepIndex, formData, setFormData} =
    useContext(FormContext);
  const yesNoAnswers = [
    {name: t("S03.S013a"), value: "Yes"},
    {name: t("S03.S013b"), value: "No"},
    {name: t("S03.S013c"), value: "not"},
  ];
  const familyAndLifestyle = [
    {name: 'father_myopia', display: t("S03.S117"), type: 'radio', options: yesNoAnswers},
    {name: 'mother_myopia', display: t("S03.S118"), type: 'radio', options: yesNoAnswers},
    {name: 'TV_distance', display: t("S03.S122"), type: 'number', unit: 'select', options: ['m', 'ft']},
    {name: 'TV_H', display: t("S03.S112a"), type: 'number', placeholder: t("S03.S023")},
    {name: 'p1_readingwritingH', display: t("S03.S121"), type: 'number', placeholder: t("S03.S023")},
    {name: 'p1_totalnearH', display: t("S03.S123"), type: 'number', placeholder: t("S03.S023")},
    {name: 'p1_outdoorH', display: t("S03.S124"), type: 'number', placeholder: t("S03.S023")},
  ];
  const ValidationSchema = yup.object().shape({
    father_myopia: Yup.string().required("This field is required"),
    mother_myopia: Yup.string().required("This field is required"),
    p1_readingwritingH: Yup.number()
      .required("This field is required")
      .min(0)
      .max(10),
    TV_H: Yup.number()
      .required("This field is required")
      .min(0),
    TV_distance: Yup.number()
      .required("This field is required")
      .min(1)
      .max(5),
    TV_distanceUnit: Yup.string().required("This field is required"),
    p1_totalnearH: Yup.number()
      .required("This field is required")
      .min(1)
      .max(18),
    p1_outdoorH: Yup.number().required("This field is required"),
  }).test({
    name: "maximumHoursPerDay",
    test: function(values) {
      const fields = [
        "p1_readingwritingH",
        "p1_totalnearH",
        "p1_outdoorH",
        "TV_H",
      ];
      const sum = fields.reduce(
        (acc, field) => acc + (parseFloat(values[field]) || 0),
        0
      );

      if (sum <= 24) {
        setBottomErrorMessage("");
        return true;
      } else {
        setBottomErrorMessage("The sum of time activities must not exceed 24 hours.");
        return this.createError({
          path: 'maximumHoursPerDay',
          message: 'The sum of time activities must not exceed 24 hours.',
        });
      }
    },
  });
  const initialValues = {
    father_myopia: formData.father_myopia ?? '',
    mother_myopia: formData.mother_myopia ?? '',
    TV_H: formData.TV_H ?? '',
    p1_readingwritingH: formData.p1_readingwritingH ?? '',
    TV_distance: formData.TV_distance ?? '',
    TV_distanceUnit: formData.TV_distanceUnit ?? 'm',
    p1_totalnearH: formData.p1_totalnearH ?? '',
    p1_outdoorH: formData.p1_outdoorH ?? ''
  }
  const previousStep = () => {
    setActiveStepIndex(activeStepIndex-1);
  }
  return (
    <div className="mx-10">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const data = Object.assign({}, formData, values);
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({setValues}) => (
          <Form autoComplete='off'>
            <div className="text-center">
              <p className="mt-12 max-w-2xl text-xl text-gray-600 mx-auto">
                {t("S03.S018")}
              </p>
            </div>
            <div className="flex flex-wrap max-w-2xl mt-3 border rounded-lg p-5">
              <div className="w-full">
                <button type='button' onClick={() => {setValues(initialValues)}} className="float-right">
                  <TrashIcon className="survey-trash-icon"/>
                </button>
              </div>
              {familyAndLifestyle.map((question, index2) => (
                <div key={index2} className="flex w-full p-2">
                  <div className="w-1/2 flex items-center pr-2">
                    <span className="font-bold">{index2 + 1}. {question.display}</span>
                  </div>
                  <div className="w-1/2">
                    {question.type === 'select' && (
                      <Field as="select" name={question.name} className="bg-gray-200 rounded-lg p-2">
                        {question.options.map(option => <option key={option + 1}>{option}</option>)}
                      </Field>
                    )}
                    {question.type === 'number' && (
                      <div className="flex">
                        <Field name={question.name} id={question.name}
                              className="examination-section-table focus:outline-none focus:shadow-outline bg-gray-200 rounded-lg p-2 mr-2"
                              type="number" placeholder={question.placeholder}/>
                        {question.unit === 'select' && (
                          <Field as="select" name={question.name + 'Unit'} className="bg-gray-200 rounded-lg p-2">
                            {question.options.map((option) => (
                              <option key={question.options[option]} value={option}>{option}</option>
                            ))}
                          </Field>
                        )}
                      </div>
                    )}
                    {question.type === 'radio' && (
                      <div className="flex flex-wrap">
                        {question.options.map(option => (
                          <label key={option + 1} className="text-center mx-3 -translate-x-3">
                            <Field type="radio" name={question.name} value={option.value}
                                  className="form-radio text-indigo-600"/>
                            {option.name}
                          </label>
                        ))}
                      </div>
                    )}
                    <ErrorMessage className='text-red-700' name={question.name} component="div"/>
                  </div>
                </div>
              ))}
              {bottomErrorMessage && (
                <div className="text-center w-full text-red-700 mb-2">{bottomErrorMessage}</div>
              )}
            </div>
            <div className='text-right mt-2 mb-5 mr-2'>
              <button className="survey-back-btn" onClick={previousStep}>
                {t("S03.S211")}
              </button>
              <button className="survey-next-btn">
                {t("S03.S212")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FamilyAndLifestyle;