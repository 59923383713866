import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../../index.css';
import EditProfile from '../../components/Account/Account';

const Account = () => {
    return (
        <>
            <Header />
            <EditProfile />
            <Footer />
        </>
    );
}

export default Account;
