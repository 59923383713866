import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Results from './pages/Results';
import NotFound from './pages/NotFound';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Account from './pages/Account/Account';
import TermsAndRules from "./pages/TermsAndRules";
import ContactForm from './pages/ContactForm';
import "react-toastify/dist/ReactToastify.css";
import ExaminationResult from './pages/ExpertSurvey/ExaminationResult';
import ExpertSurveyAISelection from './pages/ExpertSurvey/ExpertSurveyAISelection';
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import ConfirmAccount from "./pages/ConfirmAccount";
import EfoSurvey from "./pages/Survey/EffortlessSurvey";
import {PrivateRoute, ResultsRoute, StaffRoute, ExpertRoute} from "./services/PrivateRoute";
import AdvSurvey from "./pages/Survey/AdvancedSurvey";
import AboutUs from './pages/AboutUs';
import UserVisit from "./pages/Account/UserVisit";
import Surveys from "./pages/Survey/Surveys";
function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/survey' element={<PrivateRoute />}>
            <Route path='/survey' element={<Surveys />} />
          </Route>
          <Route path='/medical-survey' element={<StaffRoute />}>
            <Route path='/medical-survey' element={<AdvSurvey />} />
          </Route>
          <Route path='/results' element={<ResultsRoute />}>
            <Route path='/results' element={<Results />} />
          </Route>
          <Route path='/signup' element={<SignUp />} />
          <Route path='/password-reset' element={<ResetPassword />} />
          <Route path='/password-reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
          <Route path='/terms' element={<TermsAndRules />} />
          <Route path='/account-confirm-email/:key' element={<ConfirmAccount />} />
          <Route path='/account' element={<PrivateRoute />}>
            <Route path='/account' element={<Account />} />
          </Route>
          <Route path='/account/visits' element={<PrivateRoute />}>
            <Route path='/account/visits' element={<UserVisit />} />
          </Route>
          <Route path='/' element={<Home />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/login' element={<SignIn />} />
          <Route path='/contact' element={<ContactForm />} />
          <Route path='/examination-result' element={<ResultsRoute />}>
            <Route path='/examination-result' element={<ExaminationResult />} />
          </Route>
          <Route path='/expert-survey' element={<ExpertRoute />}>
            <Route path='/expert-survey' element={<ExpertSurveyAISelection />} />
          </Route>
          <Route path='/expert-survey-AI-Selection' element={<PrivateRoute />}>
            <Route path='/expert-survey-AI-Selection' element={<ExpertSurveyAISelection />} />
          </Route>
          <Route path='/about' element={<AboutUs />} />
          <Route path='/sections' element={<EfoSurvey/>} />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
