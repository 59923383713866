import React, { Fragment, useContext, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { AuthContext } from '../contexts/AuthContext';
import {useNavigate} from "react-router-dom";
import FontSizeSelector from './FontSizeSelector';
import axios from "../api/axios";
import logo from '../logo.webp';
import {
  CalculatorIcon,
  ChartBarIcon,
  LogoutIcon,
  MenuIcon,
  PhoneIcon,
  RefreshIcon,
  UserIcon,
  CalendarIcon,
  BookOpenIcon,
} from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';


{/* For section "Home page" */}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AuthContext)
  const navigate = useNavigate();
  const REFRESH_URL = '/token/refresh/'
  const USER_URL = '/user/' + user.uuid;
  const solutions = [
    {
      name: t("H.H2"),
      description: 'Your information for an eye test',
      href: '/survey',
      icon: CalculatorIcon,
    },
    // {
    //   name: t("H.H5"),
    //   description: 'Contact with doctor on website',
    //   href: '/contact',
    //   icon: PhoneIcon,
    // },
    {
      name: t("H.H6"),
      description: 'About us',
      href: '/about',
      icon: BookOpenIcon,
    },
  ]

  useEffect(() => {
    if (user.email) {
      axios.post((REFRESH_URL))
          .then((res) => {
            if(res.status !== 200)
            {
              localStorage.removeItem('user');
              setUser({})
              navigate("/");
            }
          })
        .catch(()=>{
          localStorage.removeItem('user');
          setUser({})
          navigate("/");
        })
    }
  }, []);

  const logout = (e) => {
    e.preventDefault();

    axios.post('/logout/')
        .then(() => {
          localStorage.removeItem('user');
          setUser({})
          navigate("/");
        })
  }

  if (sessionStorage.getItem('font-size') === 'big') {
    document.querySelector('html').classList.add('big-font');
  }

  return (
    <Popover className="relative bg-white bg-nightwind-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-2">
        <div className="flex justify-between items-center py-6 md:justify-between md:space-x-4">
          <div className="flex justify-start lg:w-0 lg:flex-auto">
            <a href="/">
              <span className="sr-only">MRC-logo</span>
              <img
                className="h-12 w-auto object-cover sm:h-12"
                src={logo}
                width="161"
                height="58"
                alt="logo"
              />
            </a>
          </div>

          {/* Menu button on small screen */}
          {!user.email ? (
            <div className="-mr-2 -my-2 lg:hidden flex items-center">
              <LanguageSelector/>
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
              </Popover.Button>
            </div>
              ) : (
            <>
              <div className="mr-2 -my-2 lg:hidden flex items-center ">
                <LanguageSelector/>
                <Popover.Button className="animation-menu-button justify-between">
                  <span className="sr-only">Open menu</span>
                  <span>{user.first_name } {user.last_name }</span>
                </Popover.Button>
                <ChevronDownIcon className='text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-600' aria-hidden="true"/>
              </div>
            </>
          )}
          {/* Pages on full screen */}
          <Popover.Group as="nav" className="hidden lg:flex space-x-10">
            <a href="/" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>
              {t("H.H1")}
              {user.is_debug_account && (
                  <span className='tooltip-text'>
                  H:H1
                </span>
              )}
            </a>
            <a href="/survey" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>
              {t("H.H2")}
              {user.is_debug_account && (
                  <span className='tooltip-text'>
                  H:H2
                </span>
              )}
            </a>
            {/*{(user.is_doctor || user.is_debug_account) && (*/}
            {/*<a href="/medical-survey" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>*/}
            {/*  {t("H.H3")}*/}
            {/*  {user.is_debug_account && (*/}
            {/*      <span className='tooltip-text'>*/}
            {/*      H:H3*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</a>*/}
            {/*)}*/}
            {/*{(user.is_data_science || user.is_debug_account) && (*/}
            {/*<a href="/expert-survey" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>*/}
            {/*  {t("H.H4")}*/}
            {/*  {user.is_debug_account && (*/}
            {/*      <span className='tooltip-text'>*/}
            {/*      H:H4*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</a>*/}
            {/*)}*/}
            {/*<a href="/contact" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>*/}
            {/*  {t("H.H5")}*/}
            {/*  {user.is_debug_account && (*/}
            {/*      <span className='tooltip-text'>*/}
            {/*      H:H5*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</a>*/}
            <a href="/about" className={`animation-menu-button ${user.is_debug_account ? 'tooltip' : ''}`}>
              {t("H.H6")}
              {user.is_debug_account && (
                  <span className='tooltip-text'>
                  H:H6
                </span>
              )}
            </a>
          </Popover.Group>
          {!user.email ? (
            <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
              <LanguageSelector/>
              <FontSizeSelector />
              {/* Login */}
              <a
                href="/login" 
                className="whitespace-nowrap text-base font-medium py-2 text-indigo-500 hover:text-indigo-900 duration-150 hover:scale-110"
              >
                {t("sign-in")}
              </a>
              {/* Register */}
              <a
                href="/register"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                {t("sign-up")}
              </a>
            </div>
              ) : (
            <>
              <Popover.Group className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
              <LanguageSelector/>
              <FontSizeSelector />
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-black' : 'text-gray-600', 'group bg-white rounded-md inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 hover:text-black duration-150 hover:scale-110 focus:ring-indigo-500'
                      )}
                    >
                      <span>{user.first_name } {user.last_name }</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-black' : 'text-gray-600',
                          'ml-2 h-5 w-5 group-hover:text-gray-600'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      {/* Subpages "resources" for More page */}
                      <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-48 max-w-md sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <a href="/account" className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100">
                              <UserIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-black">
                                  {t("account")}
                                </p>
                              </div>
                            </a>
                            <a href="/account/visits" className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100">
                              <CalendarIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-black">
                                  Visits
                                </p>
                              </div>
                            </a>
                            <a 
                              href="/" 
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
                              onClick={(e) => {
                                logout(e);
                              }}
                            >
                              <LogoutIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-black">
                                  {t("logout")}
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            </>
          )}
        </div>
      </div>
        

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {/* Expansion Menu button on small screen */}
        <Popover.Panel focus className="top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {/* Solutions list in Menu button */}
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-100"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-black">{item.name}</span>
                    </a>
                  ))}
                  {user.is_data_science && (
                    <a 
                      key='Analytics'
                      href="/expert-survey"
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-100"
                    >
                      <ChartBarIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-black">{t("H.H4")}</span>
                    </a>
                  )}
                  {user.is_doctor && (
                    <a 
                      key='Medical survey'
                      href="/medical-survey"
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-100"
                    >
                      <RefreshIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-black">{t("H.H3")}</span>
                    </a>
                  )}
                </nav>
              </div>
            </div>
            
            {/* Login and Register in Menu button*/}
            <div className="px-14 py-6">
              {!user.email ? (
                <div>
                  {/* Login */}
                  <a 
                    href="/login"
                    className="whitespace-nowrap text-base font-medium text-indigo-500 hover:text-black"
                  >
                    {t("sign-in")}
                  </a>
                  {/* Register */}
                  <a
                    href="/register"
                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    {t("sign-up")}
                  </a>
                </div>
                  ) : (
                <div>
                  {/* Account */}
                  <a
                    href="/account"
                    className="whitespace-nowrap px-3 text-base font-medium text-indigo-500 hover:text-black"
                  >
                    {t("account")}
                  </a>
                  {/* Visits */}
                  <a
                    href="/account/visits"
                    className="whitespace-nowrap px-3 text-base font-medium text-indigo-500 hover:text-black"
                  >
                    Visits
                  </a>
                  {/* Logout */}
                  <a 
                    href="/" 
                    className="ml-8 whitespace-nowrap inline-flex items-center px-10 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    onClick={(e) => {
                      logout(e);
                    }}
                  >
                    {t("logout")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
