import { useEffect, useState} from "react";
import axios from "../api/axios";
import {useNavigate, useParams} from "react-router-dom";
import { CheckIcon, EmojiSadIcon} from '@heroicons/react/outline'

const error = {
    title: "Verification error",
    desc: "Please try again.",
    icon: EmojiSadIcon,
}

const success = {
    title: "Account verified",
    desc: "You will be redirected to the login page.",
    icon: CheckIcon,
}

export default function ConfirmEmail() {
    const { key } = useParams();
    const [status, setStatus] = useState(undefined);
    const notification = status ? success : error
    const CONFIRM_URL = 'account-confirm-email/'
    const navigate = useNavigate()

    useEffect(() => {
        axios.post(CONFIRM_URL, {key})
            .then((res) => {
                if (res.status === 200) {
                    setStatus(true)
                    setTimeout(() => {
                        navigate("/login");
                    }, 4000);
                } else {
                    setStatus(false)
                }
            })
            .catch(() => {
                setStatus(false)
            });
    },[]);


    return (
        <>
            <div className="bg-white min-h-full px-4 py-24 sm:px-6 sm:py-56 md:grid md:place-items-center lg:px-8">
                <div className="max-w-masx mx-auto">
                    {status===undefined ? (
                        <div className='loader'></div>
                    ) : (
                    <main className="sm:flex">
                        <notification.icon className="flex-shrink-0 h-12 w-12 text-indigo-600" aria-hidden="true" />
                        <div className="sm:ml-6">
                            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{notification.title}</h1>
                                <p className="mt-1 text-base text-gray-500">{notification.desc}</p>
                            </div>
                        </div>
                    </main>
                    )}
                </div>
            </div>
        </>
    )
}