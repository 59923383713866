import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ResetPwd from "../components/ResetPassword";
import '../index.css';

const ResetPassword = () => {
    return (
        <>
            <Header />
            <ResetPwd />
            <Footer />
        </>
    );
}

export default ResetPassword;