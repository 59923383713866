export default function UserVisits() {
  return (
    <>
      <div className='sm:flex justify-center'>
        <div className="min-h-full flex flex-col sm:px-6 lg:px-8 sm:mb-10 sm:w-full sm:max-w-[70%]">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">Visit history</h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-2 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mb-3 text-center text-1xl font-extrabold text-gray-900">Empty</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}