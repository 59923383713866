import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from '../components/Login';
import '../index.css';

const SignIn = () => {
    return (
        <>
            <Header />
            <Login />
            <Footer />
        </>
    );
}

export default SignIn;
