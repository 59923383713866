import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

export default function Center() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("H.C3"),
      name_id: 'H:C3',
      description: t("H.C31"),
      desc_id: 'H:C31',
      icon: GlobeAltIcon,
    },
    {
      name: t("H.C4"),
      name_id: 'H:C4',
      description: t("H.C41"),
      desc_id: 'H:C41',
      icon: ScaleIcon,
    },
    {
      name: t("H.C5"),
      name_id: 'H:C5',
      description: t("H.C51"),
      desc_id: 'H:C51',
      icon: LightningBoltIcon,
    },
    {
      name: t("H.C6"),
      name_id: 'H:C6',
      description: t("H.C61"),
      desc_id: 'H:C61',
      icon: AnnotationIcon,
    },
  ]

  const { user } = useContext(AuthContext);
  return (
    <div className="bg-white">
      {user.is_debug_account ?
          <div className="site-id">
            H
          </div>
      : null}
      <Banner />
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
            {t("H.C1")}
            {user.is_debug_account && (
                <span className='tooltip-text'>
                  H:C1
                </span>
            )}
          </p>
          <p className={`mt-4 max-w-7xl justify-between text-xl text-gray-500 lg:mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
            {t("H.C2")}
            {user.is_debug_account && (
                <span className='tooltip-text'>
                  H:C2
                </span>
            )}
          </p>
        </div>

        <div className="max-w-7xl mx-auto mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className={`ml-16 text-lg leading-6 font-medium text-gray-900 ${user.is_debug_account ? 'tooltip' : ''}`}>{feature.name}
                    {user.is_debug_account && (
                        <span className='tooltip-text'>
                  {feature.name_id}
                </span>
                    )}
                  </p>
                </dt>
                <dd className={`mt-2 ml-16 text-base text-gray-500 ${user.is_debug_account ? 'tooltip' : ''}`}>{feature.description}
                  {user.is_debug_account && (
                      <span className='tooltip-text'>
                  {feature.desc_id}
                </span>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
