import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AdvancedSurvey from "../../components/Survey/AdvancedSurvey";
import '../../index.css';

const AdvSurvey = () => {
    return (
        <>
            <Header />
            <AdvancedSurvey/>
            <Footer />
        </>
    );
}

export default AdvSurvey;
