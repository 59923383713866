import {useContext, useEffect} from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { AuthContext } from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";

export default function ResetPwd() {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();
    const RESET_URL = "/password/reset/";
    
    useEffect(() => {
        if (user.email) {
            navigate('/');
        }
    });

    return (
        <>
            {!user.email ? (
                <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
                    </div>

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={async (values) =>{
                                    axios.post(RESET_URL, values)
                                        .then((res) => {
                                            if (res.status === 200) {
                                                toast.success('If the email address entered is associated with an account, you will receive a reset link.');
                                            }
                                        })
                                        .catch((error) => {
                                            toast.error(error.response.data);
                                        });
                                }}
                                >
                                <Form className="space-y-6">

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="captcha" className="block text-sm font-medium text-gray-700">
                                            Captcha
                                        </label>
                                        <div className="bg-gray-100 rounded-lg px-4 py-16 sm:p-16">
                                            {/* Some diagram */}
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            ):(
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">You are already logged in!</h1>
                </div>
            )}
        </>
    )
}
