import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import ResultContext from "../contexts/ResultContext";

export const PrivateRoute = () => {
    const { user } = useContext(AuthContext);

    return (
        user.uuid ? <Outlet /> : <Navigate to="/login" />
    );
};

export const ResultsRoute = () => {
    const { result } = useContext(ResultContext);

    return (
        result ? <Outlet /> : <Navigate to="/survey" />
    );
};

export const StaffRoute = () => {
    const { user } = useContext(AuthContext);

    return (
        user.is_doctor || user.is_data_science || user.is_debug_account ? <Outlet /> : <Navigate to="/" />
    );
};

export const ExpertRoute = () => {
    const { user } = useContext(AuthContext);

    return (
        user.is_data_science || user.is_debug_account  ? <Outlet /> : <Navigate to="/" />
    );
};
