import { useTranslation } from 'react-i18next';
import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

export default function Terms() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    return (
        <div className="bg-white">
            {user.is_debug_account ?
                <div className="site-id">
                    S01
                </div>
                : null}
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <p className={`mt-2 py-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl ${user.is_debug_account ? 'tooltip' : ''}`}>
                        {t("S02.S1")}
                        {user.is_debug_account && (
                            <span className='tooltip-text'>
                                S01:S1
                            </span>
                        )}
                    </p>
                    <p className={`mt-4 max-w-7xl justify-between text-xl text-gray-500 lg:mx-auto ${user.is_debug_account ? 'tooltip block' : ''}`}>
                        {t("S02.S11")}
                        {user.is_debug_account && (
                            <span className='tooltip-text'>
                                S01:S11
                            </span>
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}
