import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Err404 from '../components/Err404';
import '../index.css';

const NotFound = () => {
    return (
        <>
            <Header />
            <Err404 />
            <Footer />
        </>
    );
}

export default NotFound;
