import React from 'react';
import Header from '../components/Header';
import Terms from "../components/Terms";
import '../index.css';

const TermsAndRules = () => {
    return (
        <>
            <Header />
            <Terms />
        </>
    );
}

export default TermsAndRules;
